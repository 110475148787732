import React, { useState, useEffect, useRef } from 'react';

import './App.css';
import Navbar from './navbar.jsx';
import Progress_bar from './progress';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { MdMoreHoriz, MdEdit, MdRefresh, MdWarning, MdErrorOutline, MdInfoOutline, MdStar } from 'react-icons/md'
import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Divider, AccordionIcon,
    Tooltip, Spacer, Textarea, MenuItem, Menu, MenuButton, MenuList, Toast, useToast, Circle,
    Table, Thead, Tbody, Tr, Th, Td
} from '@chakra-ui/react'
import { MoonIcon, SunIcon, ChevronDownIcon, AddIcon, SettingsIcon, ChevronUpIcon, DeleteIcon, CheckIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme.js';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from './firebase';
import { db } from './firebase';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import RFIList from './rfiList.jsx';
import SidePopup from './sidePopup.jsx';
import ExpandableText from './ExpandableText.jsx';
import { SendUpdate } from './backend.js';
import './CircleStatsStyle.css';
import { FaUser, FaProjectDiagram, FaBuilding, FaClipboardList, FaToolbox, FaStar, FaEllipsisH, FaCheck } from "react-icons/fa";
import { GoDotFill, GoDot } from "react-icons/go";
import { getUserFromID, fetchProjectSubcontractors } from './backend.js';



function timestampToDDMM(timestamp) {
    const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);

    // Create a new Date object from the milliseconds
    const date = new Date(milliseconds);

    // Extract the day and month parts
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    // Return the formatted string
    return `${day}/${month}`;
}


const PriorityIcon = ({ priority }) => {
    switch (priority) {
        case 'High':
            return <Tooltip label={<Text>This RFI has been marked as <Text as="span" color="red.500">high</Text> priority</Text>} fontSize='md' hasArrow bg={'gray.200'}>
                <HStack justifyContent={'left'} spacing={0}>
                    <Icon as={GoDotFill} style={{ color: '#ed3e58' }} />
                    <Icon as={GoDotFill} style={{ color: '#ed3e58' }} />
                    <Icon as={GoDotFill} style={{ color: '#ed3e58' }} />

                </HStack>
            </Tooltip>
        case 'Medium':
            return <Tooltip label={<Text>This RFI has been marked as <Text as="span" color="orange.500">medium</Text> priority</Text>} fontSize='md' hasArrow bg={'gray.200'}>
                <HStack spacing={0} justifyContent={'left'}>
                    <Icon as={GoDotFill} style={{ color: '#f09a4a' }} />
                    <Icon as={GoDotFill} style={{ color: '#f09a4a' }} />
                    <Icon as={GoDot} style={{ color: '#f09a4a' }} />

                </HStack>

            </Tooltip>
        case 'Low':
            return <Tooltip label={<Text>This RFI has been marked as <Text as="span" color="blue.500">low</Text> priority</Text>} fontSize='md' hasArrow bg={'gray.200'}>
                <HStack spacing={0} justifyContent={'left'}>
                    <Icon as={GoDotFill} style={{ color: '#4bb2f2' }} />
                    <Icon as={GoDot} style={{ color: '#4bb2f2' }} />
                    <Icon as={GoDot} style={{ color: '#4bb2f2' }} />
                </HStack>
            </Tooltip>
        default:
            return null;
    }
};

function ProjectList({ projects, userList, uid, allRFIs, userInfo }) {
    const [localProjects, setLocalProjects] = useState([])
    const [userFavouriteTenders, setUserFavouriteTenders] = useState([])
    const [selectedProjectSubbies, setSelectedProjectSubbies] = useState([])
    const toast = useToast()

    

    function pullFavoritesToFront(p, favoriteIds) {
        // Filter out the favorite projects and the non-favorite projects
        const favoriteProjects = p.filter(project => favoriteIds.includes(project.id));
        const nonFavoriteProjects = p.filter(project => !favoriteIds.includes(project.id));

        // Concatenate the favorite projects array with the non-favorite projects array
        const updatedProjectsArray = favoriteProjects.concat(nonFavoriteProjects);

        return updatedProjectsArray;
    }


    useEffect(() => {
        //Rearrange array to move favourites to the front
        console.log(userFavouriteTenders)
        const reorderedProjects = pullFavoritesToFront(projects, userFavouriteTenders)
        setLocalProjects(reorderedProjects)
    }, [projects, userFavouriteTenders])

    const showToast = () => {
        toast({
            title: 'Marked as resolved',
            description: 'RFI will be archived when the contractor agrees that it is resolved.',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }

    const [replyText, setReplyText] = useState('')

    const handleInputChange = (e) => {
        setReplyText(e.target.value)
    };

    const handleSend = () => {
        console.log('Sending: ', replyText)
    }

    const handleSendButtonClick = async (r) => {
        handleSend()

        const path = 'RFIs/' + r.id

        await SendUpdate(path, replyText, 'message', userInfo.id, 'contractor', userInfo.company)
        showToast()

        setShowReplyBox(false)
        setReplyText('')
    }


    const handleRemove = async (projectID) => {
        console.log('removed ', projectID, ' user ', uid)

        const userDocRef = doc(db, 'users', uid);


        await updateDoc(userDocRef, {
            tenders: arrayRemove(projectID)
        });
    };

    const handleRemoveButtonClick = async (tenderID) => {
        await handleRemove(tenderID);
    };

    const [selectedProject, setSelectedProject] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [pendingResolved, setPendingResolved] = useState(false);

    useEffect(() => {
        const fetchFunction = async() => {
            if(selectedProject){
                const s = await fetchProjectSubcontractors(selectedProject)
                console.log(s)
                setSelectedProjectSubbies(s)
            }
        }

        fetchFunction();
        
    }, [selectedProject])

    const handleClick = (project) => {
        setSelectedProject(project);
        setModalOpen(true);
    };

    const handleMenuButtonClick = (option, RFI) => {
        const path = 'RFIs/' + RFI.id
        if (option == 'resolvedButton') {
            SendUpdate(path, '', 'markedAsResolved', uid, 'contractor', userInfo.company)
            showToast()
            console.log('Marked As Resolved')
        }
        if (option == 'workingOnIt') {
            //SendUpdate(path, '', 'markedAsResolved', uid, 'contractor', userInfo.company)
            console.log('Working On It')
        }
        if (option == 'waitingForClient') {
            //SendUpdate(path, '', 'markedAsResolved', uid, 'contractor', userInfo.company)
            console.log('Waiting For Client')
        }
    }

    const closeModal = () => {
        setSelectedProject(null);
        setModalOpen(false);
    };

    const handleRFIClick = async (RFI) => {
        //Check if RFI has been opened - if not, set it to opened & send an update to the server
        const path = 'RFIs/' + RFI.id
        console.log('RFI IS', RFI)
        const updates = RFI.updates


        const lastSubcontractorUpdate = RFI.updates.reverse().find(u => u.userType === "subcontractor");
        const updateIndex = updates.indexOf(lastSubcontractorUpdate)
        console.log('Last update: ', lastSubcontractorUpdate)
        console.log(updateIndex)

        if (lastSubcontractorUpdate.read == false) {
            const docRef = doc(db, path);
            updates[updateIndex].read = "true"
            console.log('New Updates:', updates)
            console.log('not read yet')

            await updateDoc(docRef, {
                updates: updates
            });

            //SendUpdate(path, '', 'read', uid, 'contractor', userInfo.company)
        }

    }

    const handleConfirmClick = async (RFI) => {
        const path = 'RFIs/' + RFI.id

        await SendUpdate(path, '', 'confirmResolved', userInfo.id, 'contractor', userInfo.company)
        showToast()
    }

    const handleDenyClick = async (RFI) => {
        const path = 'RFIs/' + RFI.id
        showToast()
        await SendUpdate(path, '', 'denyResolved', userInfo.id, 'contractor', userInfo.company)
    }

    const rfisReferencingTenders = allRFIs.filter((rfi) => {
        // Check if the referenced tender exists in the list of tenders
        if (rfi.tender != undefined) {
            return projects.some((tender) => tender.id === rfi.tender.id);
        }
        else return null
    });

    const [showReplyBox, setShowReplyBox] = useState(false)

    const handleShowReplyBox = () => {
        setShowReplyBox((prevHideReplyBox) => !prevHideReplyBox);
    }

    function CircleWithNumber({ icon, number, text }) {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <Tooltip
                label={text}
                openDelay={1000} // Show tooltip after 1 second
                hasArrow
            >
                <Circle
                    borderColor={'#E2E8F0'}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    className={`circle ${isHovered ? "hovered" : ""}`}
                >
                    <Stack alignItems={"center"}>
                        {isHovered ? (
                            <Text className="number">{number}</Text>
                        ) : (
                            <Icon as={icon} color="gray.500" boxSize="20px" />
                        )}
                    </Stack>
                </Circle>
            </Tooltip>
        );
    }

    function daysUntil(targetTimestamp) {
        // Get current timestamp in milliseconds
        const currentTimestamp = Date.now();

        // Convert target timestamp to milliseconds
        const targetTimestampMs = targetTimestamp.toDate().getTime();

        // Calculate the time difference in milliseconds
        const timeDifferenceMs = targetTimestampMs - currentTimestamp;

        // Convert milliseconds to days
        const daysDifference = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

        const positive = daysDifference * -1;

        switch (daysDifference) {
            case -1:
                return (
                    <Text fontSize="xl" fontWeight="bold" color="red.400">
                        Yesterday
                    </Text>
                )
                break;
            case 1:
                return (
                    <Text fontSize="xl" fontWeight="bold" color="orange.400">
                        Tomorrow
                    </Text>
                );
                break;
            case 0:
                return (
                    <Text fontSize="xl" fontWeight="bold" color="orange.400">
                        Today
                    </Text>
                )
                break;
            default:
                if (daysDifference > 1) {
                    return (<Text fontSize="xl" fontWeight="bold" color="teal.400">
                        {daysDifference} Days
                    </Text>
                    )
                }
                else if (daysDifference < -1) {
                    return (
                        <Text fontSize="xl" fontWeight="bold" color="red.400">
                            {positive} Days ago
                        </Text>
                    );
                }
                break;
        }
    }

    function moveProjectToFront(projectObj, projects, setProjects) {
        // Find the index of the project in the array
        const index = projects.findIndex(project => project.id === projectObj.id);

        if (index !== -1) {
            // If the project is found, move it to the front/start of the array
            const updatedProjects = [
                projects[index], // Place the project at the front
                ...projects.slice(0, index), // Exclude the project from its original position
                ...projects.slice(index + 1) // Include the rest of the projects after the project
            ];

            // Update the state with the new array where the project is moved to the front/start
            setLocalProjects(updatedProjects);
        } else {
            // If the project is not found in the array, do nothing or handle the error accordingly
            console.error("Project not found in the array.");
        }
    }

    function slotUnfavoritedProjectBack(project, projectsArray, favoriteCount) {
        // Determine if the project is favorited
        const isFavorited = userFavouriteTenders.includes(project.id);

        // Find the index of the project in the array
        const currentIndex = projectsArray.findIndex(item => item.id === project.id);

        // Remove the project from its current position in the array
        if (currentIndex !== -1) {
            projectsArray.splice(currentIndex, 1);
        }

        // Find the index where the project should be inserted based on its favorited status and timestamp
        let insertIndex = projectsArray.findIndex((item) => {
            // If the project is favorited, it should be placed before other favorited projects
            // Otherwise, it should be placed after all favorited projects and based on its timestamp
            return (isFavorited && userFavouriteTenders.includes(item.id)) || (item.quotesDue > project.quotesDue);
        });

        // Adjust the insert index based on the count of favorites
        insertIndex += favoriteCount;

        // If no suitable position is found, insert the project at the end of the array
        if (insertIndex === -1) {
            insertIndex = projectsArray.length;
        }

        // Insert the project into the array at the determined position
        projectsArray.splice(insertIndex, 0, project);

        return projectsArray;
    }

    useEffect(() => {
        setUserFavouriteTenders(userInfo.favouriteTenders)
        //console.log('FAVOURITES : ', userFavouriteTenders)
    }, [userInfo])

    const toggleFavourite = async (project) => {
        let favourites = userFavouriteTenders
        if (userFavouriteTenders.includes(project.id)) {
            //Is favourite so toggle FALSE 
            favourites = favourites.filter(p => p != project.id)
            setUserFavouriteTenders(favourites)
            const newArray = slotUnfavoritedProjectBack(project, localProjects, userFavouriteTenders.length)
            setLocalProjects(newArray)

            //Update firestore
            const ref = doc(db, "users", userInfo.id)

            await updateDoc(ref, {
                favouriteTenders: favourites
            })
            console.log(favourites)
        } else {
            //Is not favourite so toggle TRUE 
            favourites.push(project.id)
            setUserFavouriteTenders(favourites)
            moveProjectToFront(project, localProjects, setLocalProjects)

            //Update firestore
            const ref = doc(db, "users", userInfo.id)

            await updateDoc(ref, {
                favouriteTenders: favourites
            })
            console.log(favourites)
        }
        //console.log(project)
    }

    const [showTrades, setShowTrades] = useState(false)
    const handleShowTrades = () => {
        setShowTrades(!showTrades)
    }

    if (localProjects) {
        return (
            <ChakraProvider theme={theme}>
                <Box>
                    <SimpleGrid columns={3} spacing={12} mx={'1%'}>
                        {localProjects.map((p, index) => (
                            <Card bg="white" key={p.id} position="relative">
                                {/* Favorite Star Icon */}
                                <Flex
                                    position="absolute"
                                    top="5px"
                                    right="5px"
                                    flexDir="column"
                                    alignItems="center"
                                >
                                    <IconButton
                                        aria-label="Favorite"
                                        icon={<FaStar color={(userFavouriteTenders && index < userFavouriteTenders.length) ? "gold" : "gray"} />}
                                        onClick={() => { toggleFavourite(p) }}
                                        bg="transparent"
                                        color="gray.500"
                                        size="sm" // Adjust size here
                                        p="0" // Adjust padding here
                                    />
                                    <IconButton
                                        aria-label="Options"
                                        icon={<FaEllipsisH />}
                                        bg="transparent"
                                        color="gray.500"
                                        size="sm" // Adjust size here
                                        p="0" // Adjust padding here
                                    />
                                </Flex>
                                <CardBody onClick={() => handleClick(p)}>
                                    <Stack justifyContent="space-between">
                                        <Heading size="sm" pb="5%" textAlign={'left'}>
                                            {p.name && p.name}
                                        </Heading>
                                        <Flex justify="space-between" mt={'2%'}>
                                            <Box flex="1">
                                                <Box>
                                                    {daysUntil(p.quotesDue)}
                                                </Box>
                                                <Text fontSize="sm" fontWeight="semibold" color="gray.600">
                                                    Quotes Due
                                                </Text>
                                            </Box>
                                            <Box flex="1">
                                                <Text fontSize="xl" fontWeight="bold" color="teal.400">
                                                    {p.rfis.length}
                                                </Text>
                                                <Text fontSize="sm" fontWeight="semibold" color="gray.600">
                                                    Active RFIs
                                                </Text> 
                                            </Box>
                                        </Flex>

                                    </Stack>
                                </CardBody>
                                <CardFooter borderTopWidth={'2px'} borderTopColor={'teal.100'}>
                                    <Flex justify="space-around" w="100%">
                                        <CircleWithNumber icon={FaUser} number={p.subcontractors.length} text={'Subcontractors'} />
                                        <CircleWithNumber icon={FaCheck} number={p.resolvedRFIs} text={'Resolved RFIs'} />
                                        <CircleWithNumber icon={FaClipboardList} number={'x'} text={'RFIs waiting on you'} />
                                    </Flex>
                                </CardFooter>
                            </Card>
                        ))}
                    </SimpleGrid>

                    <Modal isOpen={isModalOpen} onClose={closeModal} >
                        <ModalOverlay />
                        <ModalContent maxW="50%" minH="450px">
                            <ModalHeader>
                                <Heading size={'md'}>{selectedProject && selectedProject.name}</Heading>
                                <Text fontSize={'sm'}>Project ID: {selectedProject && selectedProject.id}</Text>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Stack>
                                    <Tabs>
                                        <TabList>
                                            <Tab>RFIs</Tab>
                                            <Tab>Subcontractors</Tab>
                                            <Tab>Details</Tab>
                                        </TabList>

                                        <TabPanels>

                                            <TabPanel sx={{ padding: 0, margin: 0 }} >

                                                <Flex bg="teal.200" p={4} height={'25%'} >
                                                    <Box flex="2" >
                                                        <Text fontWeight="bold">Submitted By</Text>
                                                    </Box>
                                                    <Box flex="1" textAlign={'left'}>
                                                        <Text fontWeight="bold">Trade</Text>
                                                    </Box>
                                                    <Box flex="0.75">
                                                        <Text fontWeight="bold">Received</Text>
                                                    </Box>
                                                    <Box flex="0.25" align="center" justify="center">
                                                        <Icon as={MdWarning} style={{ color: 'gray' }} />
                                                    </Box>
                                                </Flex>
                                                <Accordion allowToggle  >
                                                    {selectedProject != undefined && rfisReferencingTenders.map((item) =>
                                                        item.tender.id === selectedProject.id && (
                                                            <AccordionItem key={item.id}>
                                                                <Box>
                                                                    <AccordionButton w={'100%'} _expanded={{ bg: 'gray.50' }} onClick={() => { handleRFIClick(item) }}>
                                                                        <Box flex="2" textAlign="left">
                                                                            <Text>{item.contractor.company}</Text>
                                                                        </Box>
                                                                        <Box flex="1" textAlign={'left'}>
                                                                            <Text>{item.trade}</Text>
                                                                        </Box>
                                                                        <Box flex="0.75">
                                                                            <Text>{item.received}</Text>
                                                                        </Box>
                                                                        <Box flex="0.25" justifyContent="flex-start">
                                                                            <PriorityIcon priority={item.urgency} />
                                                                        </Box>
                                                                    </AccordionButton>
                                                                </Box>
                                                                <AccordionPanel >
                                                                    <Box w={'100%'}>
                                                                        {item.contractorPendingResolved && <Box w={'100%'} h={'100%'} bg={'gray.200'}>
                                                                            <Box>
                                                                                <HStack w={'100%'}>
                                                                                    <Text ml={'2.5%'} pb={'3%'} fontSize={'sm'}>Builder has marked this RFI as resolved</Text>
                                                                                    <Box pb={'3%'} ml={'auto'}>
                                                                                        <HStack mr={'15%'} mt={'5%'}>
                                                                                            <Button size='xs' variant={'outline'} colorScheme='green' mx={'2%'} onClick={() => { handleConfirmClick(item) }}>Confirm</Button>
                                                                                            <Button size='xs' variant={'outline'} colorScheme='red' mx={'2%'} onClick={() => { handleDenyClick(item) }}>Deny</Button>
                                                                                        </HStack>
                                                                                    </Box>
                                                                                </HStack>
                                                                            </Box>
                                                                        </Box>
                                                                        }
                                                                        <Box>
                                                                            <Heading size={'xs'}>{item.subcontractorCompany} says</Heading>
                                                                        </Box>
                                                                        <Box>

                                                                            <ExpandableText query={item.query} maxChars={50} />
                                                                        </Box>
                                                                        {showReplyBox && <Box my={'2%'}>
                                                                            <Textarea size={'md'} placeholder='Enter your reply here...' onChange={handleInputChange}></Textarea>
                                                                            <Button colorScheme='teal' w={'100%'} mt={'1%'} onClick={() => { handleSendButtonClick(item) }}>Send</Button>
                                                                        </Box>}
                                                                    </Box>
                                                                    <Box>
                                                                        <HStack spacing={4}>
                                                                            <Button size={'sm'} colorScheme={'teal'} onClick={handleShowReplyBox}>Reply</Button>
                                                                            <Menu>
                                                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={'sm'} colorScheme='teal'>
                                                                                    Mark As
                                                                                </MenuButton>
                                                                                <MenuList>
                                                                                    <MenuItem onClick={() => { handleMenuButtonClick('resolvedButton', item) }}>Resolved</MenuItem>
                                                                                    <MenuItem onClick={() => { handleMenuButtonClick('workingOnIt', item) }}>Working On It</MenuItem>
                                                                                    <MenuItem onClick={() => { handleMenuButtonClick('waitingForClient', item) }}>Waiting For Client</MenuItem>
                                                                                </MenuList>
                                                                            </Menu>
                                                                        </HStack>
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        )
                                                    )}
                                                </Accordion>
                                            </TabPanel>
                                            <TabPanel sx={{ padding: 0, margin: 0 }}>
                                                <Box>
                                                    <Table variant="striped">
                                                        <Thead bg="teal.200">
                                                            <Tr>
                                                                <Th>Company</Th>
                                                                <Th>Name</Th>
                                                                <Th>Contact Email</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {selectedProject && selectedProjectSubbies.map((subcontractor) => (
                                                                <Tr >
                                                                    <Td>{subcontractor.company}</Td>
                                                                    <Td>{subcontractor.firstName} {subcontractor.lastName}</Td>
                                                                    <Td>{subcontractor.email}</Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                    
                                                </Box>
                                            </TabPanel>
                                            <TabPanel>
                                                <Box w={'100%'} h={'100%'}>
                                                    <Box w={'100%'} textAlign={'left'} >
                                                        <Box>
                                                            <Heading size={'md'} >{selectedProject && selectedProject.name}</Heading>
                                                            <Heading size={'sm'} >{selectedProject && selectedProject.address} , {selectedProject && selectedProject.area}</Heading>
                                                            <Divider my={'1.5%'} />
                                                            <Text pl={'2%'}>Quotes Due {selectedProject && timestampToDDMM(selectedProject.quotesDue)}</Text>
                                                            <Divider my={'1.5%'} />
                                                            <Text pl={'2%'}>Budget of {selectedProject && selectedProject.budget}</Text>
                                                            <Divider my={'1.5%'} />
                                                            {!showTrades && <Box >
                                                                <HStack w={'25%'} justifyContent={'space-between'} pl={'2%'}>
                                                                    <Text size={'md'}>{selectedProject && selectedProject.trades && selectedProject && selectedProject.trades.length} trades involved </Text>
                                                                    <Button variant={'link'} size={'md'} onClick={handleShowTrades}>Show</Button>
                                                                </HStack>
                                                            </Box>
                                                            }
                                                            {showTrades && <Box>
                                                                <HStack w={'25%'} justifyContent={'space-between'} pl={'2%'}>
                                                                    <Flex align="flex-start" direction="column" w="90%">
                                                                        <Box pl={'2%'} >
                                                                            <ul>
                                                                                {selectedProject.trades.map(project => (
                                                                                    <li>{project}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </Box>
                                                                    </Flex>
                                                                    <Button variant={'link'} size={'md'} onClick={handleShowTrades} alignSelf="flex-start">Hide</Button>
                                                                </HStack>
                                                            </Box>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                    <Divider my={'1.5%'}></Divider>
                                </Stack>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="teal" mr={3} onClick={closeModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box >
            </ChakraProvider >
        )
    }
    else return;
}


export default ProjectList;