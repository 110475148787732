'use client'
import React, { useRef } from 'react'

import { UserContext } from "./contexts/user.context";
import { useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, addDoc, collection } from 'firebase/firestore'
import { db } from './firebase';
import { MdMoreHoriz, MdEdit, MdRefresh, MdForum } from 'react-icons/md'
import { ChakraProvider, Divider, Textarea, Toast } from '@chakra-ui/react'
import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Grid, GridItem, useToast, Menu, MenuItem, MenuList, MenuButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    InputLeftElement,
    InputGroup,
    Switch,
    Select
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, AddIcon, DeleteIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import theme from './theme';
import { Chrono } from 'react-chrono';
import { SendUpdate } from './backend';
import UpdatesTimeline from './UpdatesTimeline';
import { fetchTenderFromID } from './backend';
import ExpandableText from './ExpandableText';
import ReactSelect from 'react-select';


function decideColour(progress) {
    var c = ''
    if (progress > 80) {
        c = "lightgreen";
    }
    else if (progress <= 80 && progress > 50) {
        c = "#e4f277";
    }
    else if (progress <= 50 && progress > 25) {
        c = "lightsalmon";
    }
    else {
        c = "tomato";
    }
    return c;
}

function determineBorderColour(rfi) {
    const RFIUpdates = rfi.updates;

    const orderedUpdates = RFIUpdates.sort((a, b) => b.date.toMillis() - a.date.toMillis())
    //console.log(orderedUpdates[0])
    if(rfi.resolved === true){
        return('green.400')
    }
    else if (orderedUpdates[0].updateType == 'archived') {
        return ('red.400')
    } else if (orderedUpdates[0].userType == 'subcontractor') {
        return ('blue.400')
    } else if (orderedUpdates[0].userType == 'contractor') {
        return ('yellow.400')
    }
}

function RFIPopup({ input, userInfo }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [RFI, setRFI] = useState(null)
    const [showOverview, setShowOverview] = useState(true)
    const [showHistory, setShowHistory] = useState(false)
    const [pendingResolved, setPendingResolved] = useState(false);
    const [showRespondArea, setShowRespondArea] = useState(false)
    const [respondText, setRespondText] = useState('')
    const [isModalOpen, setModalOpen] = useState(false);

    const toast = useToast()

    const showToast = () => {
        toast({
            title: 'Marked as resolved',
            description: 'This RFI has been resolved and archived. You can still access it at any time.',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }

    const showSentToast = () => {
        toast({
            title: 'Sent',
            description: 'Your update/message has been sent!',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }

    const handleHistoryClick = () => {
        setShowHistory(!showHistory)
        setShowOverview(false)
        setShowRespondArea(false)

    }

    const handleOverviewClick = () => {
        setShowOverview(!showOverview)
        setShowHistory(false)
        setShowRespondArea(false)
    }

    const handleRespondClick = () => {
        setShowRespondArea(!showRespondArea)
        setShowHistory(false)
        setShowOverview(false)
    }

    const [updates, setUpdates] = useState([])

    useEffect(() => {
        //console.log('RFI ', input)
        setRFI(input);
        //console.log(RFI.tender);
    }, [input])


    useEffect(() => {
        console.log(updates)

    }, [updates])

    const formatDate = (timestamp) => {
        const date = timestamp.toDate()
        return date
    }


    const handleConfirmClick = async () => {
        const path = 'RFIs/' + RFI.id

        await SendUpdate(path, '', 'confirmResolved', userInfo.id, 'subcontractor', userInfo.company)
        showToast()
    }

    const handleDenyClick = async () => {
        const path = 'RFIs/' + RFI.id
        showToast()
        await SendUpdate(path, '', 'denyResolved', userInfo.id, 'subcontractor', userInfo.company)
    }

    const handleRespondTextChange = (e) => {
        setRespondText(e.target.value)
    }


    const handleSendResponseClick = async () => {
        const path = 'RFIs/' + RFI.id

        await SendUpdate(path, '', 'message', userInfo.id, 'subcontractor', userInfo.company)
        showSentToast()
    }

    const handleMenuButtonClick = (option) => {
        const path = 'RFIs/' + RFI.id
        if (option == 'resolvedButton') {
            SendUpdate(path, '', 'markedAsResolved', userInfo.id, 'subcontractor', userInfo.company)
            showToast()
            console.log('Marked As Resolved')
        }
        if (option == 'respondButton') {
            //SendUpdate(path, '', 'markedAsResolved', uid, 'contractor', userInfo.company)
            console.log('Working On It')
        }
        if (option == 'waitingForClient') {
            //SendUpdate(path, '', 'markedAsResolved', uid, 'contractor', userInfo.company)
            console.log('Waiting For Client')
        }
    }

    const closeModal = () => {
        setModalOpen(false);
    };

    const openModal = async () => {
        setModalOpen(true)
        if (RFI) {
            const path = 'RFIs/' + RFI.id
            //console.log('RFI IS', RFI)
            const updates = RFI.updates
            const lastContractorUpdate = updates.reverse().find(u => u.userType == "contractor");
            const updateIndex = updates.indexOf(lastContractorUpdate)
            //console.log('Last: ', lastContractorUpdate)
            //console.log(updateIndex)
            if (lastContractorUpdate) {


                if (lastContractorUpdate.read == false) {
                    console.log("attempting to set")
                    console.log(updates[updateIndex])
                    const docRef = doc(db, path);
                    updates[updateIndex].read = "true"
                    console.log('Now: ', updates[updateIndex])
                    console.log('New Updates:', updates)


                    await updateDoc(docRef, {
                        updates: updates
                    });

                    //SendUpdate(path, '', 'read', uid, 'contractor', userInfo.company)
                }
            }
        }
        else {
            console.log('No RFI')
        }
    };

    try {
        return (
            <>
                <IconButton borderRadius={'50%'} icon={<MdForum />} onClick={openModal} size={'lg'} colorScheme='teal' />
                <Modal onClose={closeModal} isOpen={isModalOpen} size={'lg'} isCentered>
                    <ModalOverlay />
                    <ModalContent borderTopWidth={'7px'} borderTopColor={determineBorderColour(RFI)} >
                        <ModalHeader>
                            <Text>{RFI.tender.name}</Text>
                            <Box h={'100%'}>
                                {RFI.subcontractorPendingResolved && <Box w={'100%'} h={'100%'} bg={'gray.200'} borderBottomLeftRadius={'75%'} borderBottomRightRadius={'75%'} pb={'3%'} pt={'1%'}>
                                    <Box>
                                        <HStack w={'100%'}>
                                            <Text ml={'2.5%'} pb={'3%'} fontSize={'sm'}>Builder has marked this RFI as resolved</Text>
                                            <Box pb={'3%'} ml={'auto'}>
                                                <HStack mr={'15%'} mt={'5%'}>
                                                    <Button size='xs' variant={'outline'} colorScheme='green' mx={'2%'} onClick={handleConfirmClick}>Confirm</Button>
                                                    <Button size='xs' variant={'outline'} colorScheme='red' mx={'2%'} onClick={handleDenyClick}>Deny</Button>
                                                </HStack>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </Box>
                                }
                            </Box>

                        </ModalHeader>
                        <ModalBody overflowY="auto">
                            <Stack>
                                <HStack justifyContent={'space-between'}>
                                    <Button w={'35%'} colorScheme='teal' onClick={() => handleOverviewClick()}>
                                        RFI Overview
                                    </Button>
                                    <Button w={'35%'} colorScheme='teal' onClick={() => handleHistoryClick()}>
                                        History
                                    </Button>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={'md'} colorScheme='teal'>
                                            Actions
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => { handleRespondClick() }}>Respond/Edit</MenuItem>

                                            <MenuItem onClick={() => { handleMenuButtonClick('resolvedButton') }}>Mark As Resolved</MenuItem>
                                            <MenuItem onClick={() => { handleMenuButtonClick('waitingForClient') }}>Mark As Waiting For Client</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </HStack>
                                {showOverview &&
                                    <Box mx={'5%'} mt={'5%'}>
                                        <Box display="flex" flexDirection="column-reverse" alignItems="flex-start" position="relative">
                                            <Box
                                                bg="gray.100"
                                                borderRadius="0 10px 10px 10px"
                                                padding="10px"
                                                marginBottom="10px"
                                                w={'60%'}
                                                alignSelf="flex-start"
                                                position="relative"
                                                top="-5px"
                                            >
                                                {RFI.officialResponse ? (
                                                    <Box>
                                                        <Text textAlign={'left'} fontWeight={'semibold'}>{RFI.contractor.company}</Text>
                                                        <ExpandableText query={RFI.officialResponse} maxChars={25}></ExpandableText>
                                                    </Box>
                                                ) : (
                                                    <Text>{RFI.contractor.company} has not replied yet...</Text>
                                                )}
                                            </Box>
                                            <Box
                                                bg="gray.100"
                                                borderRadius="10px 0px 10px 10px"
                                                padding="10px"
                                                marginBottom="10px"
                                                w="60%"
                                                alignSelf="flex-end"
                                                position="relative"
                                            >
                                                <Text textAlign={'right'} fontWeight={'semibold'}>{userInfo.company}</Text>
                                                <ExpandableText query={RFI.query} maxChars={25}></ExpandableText>
                                            </Box>
                                        </Box>



                                    </Box>

                                }
                                {showHistory &&
                                    <Box w={'100%'} h={'100%'}>
                                        <Divider my={'1%'} />
                                        {updates && <UpdatesTimeline RFI={RFI} account={userInfo.accountType} />
                                        }
                                        <Divider />
                                    </Box>
                                }
                                {showRespondArea && <Box>
                                    <Textarea placeholder='Enter your response/update here...' onChange={handleRespondTextChange} resize={'none'} size={'md'}></Textarea>
                                    <Button colorScheme='teal' onClick={handleSendResponseClick} w={'100%'} mt={'2.5%'}>Send</Button>
                                </Box>
                                }
                            </Stack>
                        </ModalBody>
                        <ModalCloseButton />
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }
    catch (err) {
        return null;
    }
}

function NewRFIButton({ uid, userTenders, userInfo }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [tenders, setTenders] = useState(null)

    const toast = useToast()

    const showToast = () => {
        toast({
            title: 'Success',
            description: 'Your RFI has been submitted',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }


    const defaultData = {
        id: '',
        officialResponse: '',
        progress: 20,
        query: '',
        resolved: false,
        status: 'Sent',
        tender: '',
        urgency: '',
        trade: '',
        subcontractor: '',
        contractor: null,
        updates: [],
        contractorPendingResolved: false,
        subcontractorPendingResolved: false, 
        subcontractorCompany: ''
    }

    const d = new Date();


    const defaultUpdate = {
        userType: 'subcontractor',
        date: d,
        updateType: 'Message',
        text: '',
        read: false,
        company: userInfo.company
    }

    const [initialUpdate, setInitialUpdate] = useState(defaultUpdate)

    useEffect(() => {
        // console.log('update is', initialUpdate)
        setRFIData({
            ...RFIData,
            updates: [initialUpdate]
        })
    }, [initialUpdate])


    const [RFIData, setRFIData] = useState(defaultData)
    const [filteredTender, setFilteredTender] = useState(null)


    const handleUpdateChange = () => {
        const d = new Date()

        setInitialUpdate(prevUpdate => ({
            ...prevUpdate,
            date: d,
            text: RFIData.query
        }));
    }

    const addUserRFI = async (rid) => {
        try {
            const userDocRef = doc(db, 'users', uid);
            const tenderDocRef = doc(db, 'tenders', RFIData.tender)

            await updateDoc(userDocRef, {
                rfis: arrayUnion(rid)
            })
            await updateDoc(tenderDocRef, {
                rfis: arrayUnion(rid)
            })
            console.log('Added ', rid, ' to user ', uid)

        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        setRFIData({
            ...RFIData,
            subcontractor: userInfo.id,
            subcontractorCompany: userInfo.company
        })
    }, [userInfo])

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;


        setRFIData((prevRFIData) => ({
            ...prevRFIData,
            [name]: inputValue,
        }));

        handleUpdateChange();
    }

    useEffect(() => {
        if (RFIData.tender != '') {
            const result = tenders.filter((e) => e.id == RFIData.tender)
            setFilteredTender(result)
        }
        //console.log('filtered tender is ', filteredTender)
    }, [RFIData.tender])



    const createDocument = async () => {

        const docRef = await addDoc(collection(db, "RFIs"), RFIData)

        //console.log(docRef.id)

        const newDoc = doc(db, "RFIs", docRef.id);

        addUserRFI(docRef.id)

        await updateDoc(newDoc, {
            id: docRef.id
        });


        console.log('tests')
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createDocument();
        console.log('FINAL DATA: ', RFIData)
        showToast()
        //console.log(tenders)
    }

    useEffect(() => {
        setTenders(userTenders);
        //console.log('USER TENDERS ARE', tenders)
    }, [userTenders])

    const handleContractorSelectChange = (selectedOption) => {
        setRFIData((prevData) => ({ ...prevData, contractor: selectedOption }));

    };

    const handleTradeSelectChange = (selectedOption) => {
        setRFIData((prevData) => ({ ...prevData, trade: selectedOption.value }));

    };

    const handlePrioritySelectChange = (selectedOption) => {
        setRFIData((prevData) => ({ ...prevData, urgency: selectedOption.value }));

    };

    const [contractorOptions, setContractorOptions] = useState([]);
    const [tradeOptions, setTradeOptions] = useState([])

    const priorityOptions = [
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' }
    ]

    useEffect(() => {
        if (filteredTender) {
            setContractorOptions(filteredTender[0].contractors)
            const trades = filteredTender[0].trades.map(option => ({ value: option, label: option }));
            setTradeOptions(trades)
        }
    }, [filteredTender])


    useEffect(() => {
        console.log(RFIData.urgency)
        //console.log(contractorOptions)
    }, [RFIData.urgency])

    useEffect(() => {
        console.log(RFIData.contractor)
    }, [RFIData.contractor])



    if (tenders != null) {
        return (
            <ChakraProvider theme={theme}>
                <Button colorScheme='teal' borderRadius={'10%'} onClick={onOpen}>New RFI</Button>
                <Modal onClose={onClose} isOpen={isOpen} size={'lg'} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Heading size={'md'} textAlign={'center'}>New RFI</Heading>
                        </ModalHeader>
                        <ModalBody>
                            <form onSubmit={handleSubmit}>
                                <Select placeholder='Tender' value={RFIData.tender} onChange={handleInputChange} name={'tender'}>
                                    {tenders.map(tender => (
                                        <option value={tender.id}>{tender.name}</option>
                                    ))}
                                </Select>
                                <Box mt={'3%'}>
                                    <ReactSelect
                                        placeholder='Send To...'
                                        value={RFIData.contractor}
                                        onChange={handleContractorSelectChange}
                                        options={contractorOptions}
                                        getOptionLabel={(option) => option.company}
                                        getOptionValue={(option) => option}
                                    />
                                </Box>

                                <HStack mt={'3%'} w={'100%'}>

                                    <Box w={'50%'}>
                                        <ReactSelect
                                            placeholder='Trade'

                                            onChange={handleTradeSelectChange}
                                            options={tradeOptions}
                                        />
                                    </Box>

                                    <Box w={'50%'}>
                                        <ReactSelect
                                            placeholder='Urgency'

                                            onChange={handlePrioritySelectChange}
                                            options={priorityOptions}
                                        />
                                    </Box>

                                </HStack>
                                <Textarea mt={'3%'} borderRadius={'10'} value={RFIData.query} onChange={handleInputChange} name={'query'} placeholder={'Enter your query here...'} size={'sm'} resize={'none'} />
                                <Box w={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                    <Button ml={'auto'} mt={'5%'} type='submit' colorScheme='teal'>Submit</Button>
                                </Box>

                            </form>

                        </ModalBody>
                        <ModalCloseButton />
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        )
    }

}


function RFIList({ messages, userInfo, userTenders }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const [selectedRFI, setSelectedRFI] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [filteredRFIs, setFilteredRFIs] = useState([]);
    const [allRFIs, setAllRFIs] = useState([])


    async function appendPropertiesToArray(array) {
        const promises = array.map(async (item) => {
            try {
                const tenderData = await fetchTenderFromID(item.tender);
                return { ...item, tender: tenderData };
            } catch (error) {
                console.error("Error:", error);
                // Handle the error here
                return item; // Return the original item if an error occurs
            }
        });
        return Promise.all(promises);
    }

    useEffect(() => {
        if (messages) {
            const fetchData = async () => {
                try {
                    const updatedMessages = await appendPropertiesToArray(messages);
                    console.log(updatedMessages);
                    setAllRFIs(updatedMessages);

                } catch (error) {
                    console.error("Error:", error);
                    // Handle the error here
                }
            };
            fetchData();
        }
    }, [messages]);


    useEffect(() => {
        console.log('FILTERED RFIS : ', filteredRFIs)
        console.log('Showing Archived: ', showArchived)
        console.log('Search Query: ', searchQuery)
        // Filter tenders based on search query and switch status
        const filtered = allRFIs.filter(
            (t) =>
                t.tender?.name?.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (showArchived || t.status !== 'Archived')
        );
        setFilteredRFIs(filtered);
    }, [searchQuery, showArchived, allRFIs]);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSwitchChange = (event) => {
        setShowArchived(event.target.checked);
    };

    async function handleArchiveRFI() {
        if (selectedRFI) {
            const path = 'RFIs/' + selectedRFI.id;
            await SendUpdate(path, '', 'archived', userInfo.id, 'subcontractor', userInfo.company);
            console.log('Archived!')
        }
    }

    function daysUntil(targetTimestamp) {
        // Get current timestamp in milliseconds
        const currentTimestamp = Date.now();
        // Convert target timestamp to milliseconds
        const targetTimestampMs = targetTimestamp.toDate().getTime();
        // Calculate the time difference in milliseconds
        const timeDifferenceMs = targetTimestampMs - currentTimestamp;
        // Convert milliseconds to days
        const daysDifference = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
        return daysDifference;
    }

    const daysUntilText = (days) => {
        if (days === 1) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due <Text fontSize={'sm'} fontWeight="semibold" color="orange.500" display="inline">tomorrow</Text>
                </Text>
            );
        } else if (days === -1) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due <Text fontSize={'sm'} fontWeight="semibold" color="red.500" display="inline">yesterday</Text>
                </Text>
            );
        } else if (days < -1) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due <Text fontSize={'sm'} fontWeight="semibold" color="red.500" display="inline">{days * -1} days ago</Text>
                </Text>
            );
        } else if (days > 2) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due in <Text fontSize={'sm'} fontWeight="semibold" color="green.600" display="inline"> {days} days</Text>
                </Text>
            );
        } else if (days === 0) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due <Text fontSize={'sm'} fontWeight="semibold" color="green.600" display="inline">today</Text>
                </Text>
            );
        } else if (days === 2) {
            return (
                <Text fontSize={'sm'} display="inline">
                    Quotes due in <Text fontSize={'sm'} fontWeight="semibold" color="orange.500" display="inline"> {days} days</Text>
                </Text>
            );
        }
    };

    return (
        <ChakraProvider>
            <div className='custom-grid-container'>
                {/* Search bar */}
                <Box mb={'5%'} w={'100%'}>
                    <Center>

                        <Flex align="center" w={'60%'}>
                            <NewRFIButton userTenders={userTenders} uid={userInfo.id} userInfo={userInfo} />

                            <InputGroup flex="1" mx={6}>
                                <InputLeftElement pointerEvents='none'>
                                    <SearchIcon color='gray.300' />
                                </InputLeftElement>
                                <Input
                                    placeholder='Search by tender name'
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                    bg={'whiteAlpha.500'}
                                />
                            </InputGroup>
                            <Text fontSize='sm' mr={'1%'}>Show Archived</Text>
                            <Switch
                                isChecked={showArchived}
                                onChange={handleSwitchChange}
                                colorScheme='teal'
                            />
                        </Flex>
                    </Center>

                </Box>

                {/* Render RFIs */}
                <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(30%, 1fr))' width={'100%'}>
                    {filteredRFIs.map(rfi => (
                        <Card bg={'white'} key={rfi.id} borderTopWidth={'3px'} borderTopColor={determineBorderColour(rfi)}>
                            <CardBody>
                                <Heading size='sm' pb={'5%'} textAlign={'left'} >{rfi.tender && rfi.tender.name}</Heading>
                                <Text textAlign={'left'}>{rfi.tender.quotesDue && daysUntilText(daysUntil(rfi.tender.quotesDue))}</Text>
                            </CardBody>
                            <CardFooter>
                                <Flex justifyContent="space-between" alignItems="center" width="100%">
                                    <IconButton icon={<DeleteIcon />} r={rfi.id} borderRadius={'50%'} size={'lg'} bg={'red.400'} _hover={{ bg: 'red.500' }} onClick={() => { onOpen(); setSelectedRFI(rfi); }}></IconButton>
                                    <CircularProgress value={rfi.progress} thickness={'10px'} color={decideColour(rfi.progress)} />
                                    <RFIPopup input={rfi} userInfo={userInfo} />
                                </Flex>
                            </CardFooter>
                        </Card>
                    ))}
                </SimpleGrid>

                {/* AlertDialog for archive confirmation */}
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Archive RFI
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                Are you sure? You will still be able to access this RFI at any time.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme='red' onClick={() => { onClose(); handleArchiveRFI(); }} ml={3}>
                                    Archive
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </div>
        </ChakraProvider>
    );
}

export default RFIList;