import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { ChakraProvider, Input, Stack, Box, Button, Text } from '@chakra-ui/react';


const Signup = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [company, setCompany] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    }

    return (
        <ChakraProvider>

        
        <Box>
            <Stack maxW={'50%'} alignItems={'center'} ml={'25%'} mt={'10%'} >
                <Text fontSize={'200%'}>TenderPulse</Text> 
                <Input placeholder='Email' onChange={(e)=>setEmail(e.target.value)}></Input>
                <Input placeholder='Password' onChange={(e)=>setPassword(e.target.value)}></Input>
                <Button onClick={onSubmit}>Sign Up</Button>
                <br />
                <Text>
                    Already have an account? {''}
                    <NavLink to="/login" >Log In</NavLink>
                </Text>
            </Stack>
        </Box>
        </ChakraProvider>
    )
}

export default Signup