import { extendTheme } from "@chakra-ui/react"
import { toHaveFormValues } from "@testing-library/jest-dom/matchers"

const theme =  extendTheme({
    "colors": {
      "gray": {
        "50": "#F2F2F2",
        "100": "#DBDBDB",
        "200": "#C4C4C4",
        "300": "#ADADAD",
        "400": "#969696",
        "500": "#808080",
        "600": "#666666",
        "700": "#4D4D4D",
        "800": "#333333",
        "900": "#1A1A1A"
      },
      "green": {
        "50": "#E8FDEC",
        "100": "#BEF9CA",
        "200": "#94F4A8",
        "300": "#6BF087",
        "400": "#41EC65",
        "500": "#17E843",
        "600": "#13B935",
        "700": "#0E8B28",
        "800": "#095D1B",
        "900": "#052E0D"
      },
      "pink": {
        "50": "#FAEAF2",
        "100": "#F2C4DC",
        "200": "#EA9FC5",
        "300": "#E279AF",
        "400": "#D95398",
        "500": "#D12E82",
        "600": "#A72568",
        "700": "#7E1B4E",
        "800": "#541234",
        "900": "#2A091A"
      },
      "yellow": {
        "50": "#F8F8EC",
        "100": "#ECECCB",
        "200": "#DFDFA9",
        "300": "#D3D388",
        "400": "#C7C766",
        "500": "#BABA45",
        "600": "#959537",
        "700": "#707029",
        "800": "#4B4B1B",
        "900": "#25250E"
      }
    },
    "config": {
      initialColorMode: 'light',
      useSystemColorMode: false,
      
    },
    "fontSizes": {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "18px",
      xl: "20px",
      "2xl": "24px",
      "3xl": "28px",
      "4xl": "36px",
      "5xl": "48px",
      "6xl": "64px",
    }
  })

  export default theme;