'use client'
import React, { useRef } from 'react'

import { UserContext } from "./contexts/user.context";
import { useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase';
import { useNavigate } from 'react-router-dom'; import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Divider,
  useColorMode,
  Link

} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'

interface Props {
  children: React.ReactNode
}

const Links = ['Dashboard', 'Settings']
const l = ['Login', 'Sign Up']



const RightSide = ({ loggedIn, fetchedUser }) => {
  const navigate = useNavigate();

  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("Signed out successfully")
    }).catch((error) => {
      // An error happened.
    });
  }
  const handleDashboardClicked = () => {
    navigate('/Dashboard')
  }

  const handleSettingsClicked = () => {
    navigate('/Settings')
  }
  if (loggedIn) {
    return (
      <HStack spacing={10} >
        <Box whiteSpace={'nowrap'}>
          <Text>Welcome, {fetchedUser}</Text>
        </Box>
        <Menu>
          <MenuButton
            pr={'5%'}
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Avatar
              size={'sm'}
            />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleDashboardClicked}>Dashboard</MenuItem>
            <MenuDivider />
            <MenuItem>
              <Button onClick={handleLogout} bgColor={'red.400'} color={'white'}>
                Log Out
              </Button>
            </MenuItem>
            <MenuItem>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

    )
  } else {
    return (

      <Button px={2}
        py={1} colorScheme='teal' onClick={() => { navigate('/Login') }} size={'sm'}>
        Log In / Sign Up
      </Button>
    )
  }

};


export default function Simple() {
  const { user, fetchUser, emailPasswordLogin } = useContext(UserContext);
  const [fetchedUser, setFetchedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()
  const [loggedIn, setLoggedIn] = useState(false);
  const name = useRef('');

  const auth = getAuth();


  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setLoggedIn(true);
      const uid = user.uid;

      try {
        const userDocRef = doc(db, 'users', uid);
        const docSnapshot = await getDoc(userDocRef); // Use 'await' to wait for the promise to resolve

        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const n = userData.firstName;
          setFetchedUser(n);
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error getting user document:', error);
      }
    } else {
      // User is signed out
      // ...
      setLoggedIn(false);
    }
  });


  return (

    <Box bg={'gray.50'} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

        <HStack spacing={8} alignItems={'center'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <Image src={'/logo-no-background.svg'} boxSize={'10%'} />
        </HStack>
        <Flex alignItems={'center'}>
          <RightSide loggedIn={loggedIn} fetchedUser={fetchedUser} />
        </Flex>
      </Flex>



    </Box>
  )
}