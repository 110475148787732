import React, { useState, useEffect, useRef, useContext } from 'react';


import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from './firebase.js';
import { db } from './firebase.js';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion, query, where, onSnapshot } from 'firebase/firestore';
import RFIList from './rfiList.jsx';
import SidePopup from './sidePopup.jsx';
import TenderList from './tenderList.jsx';
import theme from './theme.js';
import { UserContext } from './contexts/user.context.js';


export async function SendUpdate(path, text, updateType, userID, userType, company) {
    console.log('called ', path)
    const docRef = doc(db, path);

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
        const docRef = doc(db, path);
        const date = new Date();

        try {
            const docSnapshot = await getDoc(docRef);

            if (docSnapshot.exists()) {
                const updates = docSnapshot.data().updates || [];
                const mostRecentUpdate = updates[updates.length - 1];

                const progress = parseFloat(docSnapshot.data().progress);
                const resolvedRFIs = docSnapshot.data().resolvedRFIs
                let newProgress = 0;

                if (!isNaN(progress) && progress * 1.1 < 100) {
                    newProgress = progress * 1.1;
                }



                if (mostRecentUpdate) {
                    // Mark the most recent update as read
                    mostRecentUpdate.read = true;

                    // Append another update object to the updates array

                    const newUpdate = {
                        text: '',
                        read: false,
                        updateType: updateType,
                        user: userID,
                        userType: userType,
                        date: date,
                        company: company
                        // Add other properties as needed
                    };

                    switch (updateType) {
                        case 'read':
                            newUpdate.text = 'Read this RFI'
                            await updateDoc(docRef, {
                                progress: newProgress
                            });
                            break;
                        case 'sentToClient':
                            newUpdate.text = 'Marked this RFI as sent to client'
                            await updateDoc(docRef, {
                                progress: newProgress
                            });
                            break;
                        case 'workingOnIt':
                            newUpdate.text = 'Marked this RFI as in progress'
                            await updateDoc(docRef, {
                                progress: newProgress
                            });
                            break;
                        case 'message':
                            newUpdate.text = text
                            await updateDoc(docRef, {
                                officialResponse: text,
                                progress: newProgress
                            });
                            break;
                        case 'markedAsResolved':
                            newUpdate.text = 'Marked this RFI as resolved'
                            if (userType == 'contractor') {
                                await updateDoc(docRef, {
                                    subcontractorPendingResolved: true,
                                    progress: 90
                                });
                            }
                            else if (userType == 'subcontractor') {
                                await updateDoc(docRef, {
                                    contractorPendingResolved: true,
                                    progress: 90
                                });
                            }
                            break;
                        case 'confirmResolved':
                            newUpdate.text = 'Confirmed this RFI as resolved'
                            const newResolvedAmount = resolvedRFIs + 1
                            await updateDoc(docRef, {
                                resolved: true,
                                progress: 100,
                                contractorPendingResolved: false,
                                subcontractorPendingResolved: false,
                                resolvedRFIs: newResolvedAmount
                            });
                            break;
                        case 'denyResolved':
                            newUpdate.text = 'Denied that this RFI is resolved'
                            break;
                        case 'archived':
                            newUpdate.text = 'Archived this RFI.'
                            console.log('executing')
                            await updateDoc(docRef, {
                                status: 'Archived',

                            });
                            break;
                        default:
                            newUpdate.text = 'Error, please contact support'
                    }

                    updates.push(newUpdate);
                    console.log(newUpdate)

                    // Update the Firestore document with both modifications
                    await updateDoc(docRef, {
                        updates: updates,
                    });
                    console.log('UPDATED: ', updates)
                }

            }
        } catch (error) {
            console.error('Error updating document:', error);
            // Handle the error appropriately
        }
    }
}

export async function getUserFromID(userID) {
    const ref = doc(db, "users", userID)

    const userDoc = await getDoc(ref)

    return (userDoc)
}

export async function getUserSubscription(userID) {

}

export async function fetchAllActiveTenders() {

}

export async function fetchUserTenders(userID, accountType) {


}

export async function fetchTenderFromID(tenderID) {
    const ref = doc(db, "tenders", tenderID);

    const tenderDoc = await getDoc(ref);

    return tenderDoc.data();
}

export async function testFetchUserRFIs(userID, accountType) {

}

export async function notifyUser(userID, message) {

}

export async function fetchUserFavouriteTenders(userID) {
    const ref = doc(db, "users", userID)

    const userDoc = await getDoc(ref)
    const favourites = userDoc.data().favouriteTenders
    console.log(favourites)
    return (favourites)
}

export async function fetchProjectSubcontractors(tender) {
    if (!tender || !tender.subcontractors) {
        //console.log(tender)
        console.error('Invalid tenderID or subcontractors array.');
        return []; // Return an empty array if tenderID or subcontractors are undefined/null
    }
    console.log(tender)
    const IDs = tender.subcontractors;
    const subbies = [];

    for (const userId of IDs) {
        try {
            const ref = doc(db, "users", userId)

            const userDoc = await getDoc(ref)
            subbies.push(userDoc.data())
        } catch (error) {
            console.error(`Error fetching user with ID ${userId}:`, error);
        }
    }

    return subbies;
}