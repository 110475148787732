import React, { useState, useEffect, useRef, useContext } from 'react';
import './App.css';
import Navbar from './navbar.jsx';
import Progress_bar from './progress';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { MdMoreHoriz, MdEdit, MdRefresh, MdSettingsPower } from 'react-icons/md'
import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, MenuButton, Menu, MenuList, MenuOptionGroup,
    MenuItemOption, MenuDivider, InputGroup, InputLeftElement, Divider, Select, Textarea, filter,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Checkbox,
    useToast,
} from '@chakra-ui/react'
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { MoonIcon, SunIcon, ChevronDownIcon, AddIcon, SettingsIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon, InfoIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from './firebase';
import { db } from './firebase';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion, where, query, onSnapshot, orderBy } from 'firebase/firestore';
import RFIList from './rfiList.jsx';
import SidePopup from './sidePopup.jsx';
import TenderList from './tenderList.jsx';
import theme from './theme.js';
import { UserContext } from './contexts/user.context.js';
import ProjectList from './projectList.jsx';
import {
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
} from 'recharts';
import { active } from 'd3';
import CreatableSelect from 'react-select/creatable'
import reactSelect from 'react-select';
import ReactSelect from 'react-select';
import { Chrono } from 'react-chrono';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DateStyle.css'
import axios from 'axios';
import Pagination from './Pagination.jsx';
import { testFetchUserRFIs } from './backend.js';

function generateRandomData(length) {
    const data = [];

    for (let i = 0; i < length; i++) {
        const randomDataPoint = {
            name: i.toString(),
            uv: Math.floor(Math.random() * 5000) + 1000, // Random number between 1000 and 6000
            pv: Math.floor(Math.random() * 8000) + 1000, // Random number between 1000 and 9000
            amt: Math.floor(Math.random() * 3000) + 1000, // Random number between 1000 and 4000
        };

        data.push(randomDataPoint);
    }

    return data;
}

const data = generateRandomData(7);

const CustomChart = ({ data }) => {

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 15,
                    right: 30,
                    left: 20,
                    bottom: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="teal" />
            </LineChart>
        </ResponsiveContainer>
    )
}



function NewProjectPopup({ activeTenders, uid, userInfo }) {
    const [tid, setID] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState(null);
    const [contractorInfo, setContractorInfo] = useState([]);
    const [selectedState, setSelectedState] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')

    const [storedUserID, setStoredUserID] = useState(null)

    useEffect(() => {
        console.log('User Info: ', userInfo)
        setContractorInfo([userInfo])
    }, [userInfo])

    const toast = useToast()

    const showSuccessToast = () => {
        toast({
            title: 'Success',
            description: 'Your project has been created',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }


    const handleIDChange = (event) => {
        setID(event.target.value);
    };

    useEffect(() => {
        console.log('Tenders: ', activeTenders)
    }, [activeTenders])

    const handleOpen = () => setIsOpen(true);

    let ref = doc(db, "users", 'blank');

    let sampleData = {
        name: '',
        address: '',
        suburb: '',
        trades: [],
        contractors: [],
        password: '',
        state: '',
        status: '',
        rfis: [],
        subcontractors: [],
        budget: '',
        quotesDue: null,
        favouritedBy: [],
        category: '',
        resolvedRFIs: []
    }

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        suburb: '',
        trades: [],
        contractors: [userInfo.id],
        password: '',
        state: '',
        status: '',
        rfis: [],
        subcontractors: [],
        budget: '',
        quotesDue: null,
        favouritedBy: [],
        category: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelectChange = (selectedOption) => {
        const valuesArray = selectedOption.map((item) => item.label);

        setSelected(valuesArray);
        console.log(`Option selected:`, valuesArray);
    };

    const [selectedBudget, setSelectedBudget] = useState('')

    const handleBudgetSelectChange = (selectedOption) => {
        console.log(selectedOption.value)
        setSelectedBudget(selectedOption.value)
    }

    const handleStateSelectChange = (selectedOption) => {
        console.log(selectedOption.value)
        setSelectedState(selectedOption.value)
    }

    useEffect(() => {
        setFormData({
            ...formData,
            state: selectedState
        })

    }, [selectedState])

    useEffect(() => {
        setFormData({
            ...formData,
            budget: selectedBudget
        })
    }, [selectedBudget])

    useEffect(() => {
        console.log('contractor info: ', contractorInfo)
        setFormData({
            ...formData,
            contractors: contractorInfo
        })
    }, [contractorInfo])

    const handleCategorySelectChange = (selectedOption) => {
        console.log(selectedOption.value)
        setSelectedCategory(selectedOption.value)
    }

    useEffect(() => {
        setFormData({
            ...formData,
            category: selectedCategory
        })

    }, [selectedCategory])

    useEffect(() => {
        console.log('contractor: ', formData.contractors)
    }, [formData.contractors])

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
    };

    const steps = [
        { title: 'First', description: 'Overview' },
        { title: 'Second', description: 'Description' },
        { title: 'Third', description: 'Details' },
        { title: 'Fourth', description: 'Submit' }
    ]

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    const IncrementStep = () => {
        if (activeStep < 3) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }

    const DecrementStep = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
    }

    const joinProject = () => {
        //console.log("UID: " + uid);
        //console.log("TID: " + tid);
        //console.log("Successfully added user " + uid + " to project " + tid);
        updateDoc(ref, {
            tenders: arrayUnion(tid)
        })
    }


    const handleClose = () => {
        console.log('CLOSED');
        setFormData(sampleData);
        setIsOpen(false);
        setActiveStep(0)
    }

    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [newOptions, setNewOptions] = useState([])

    const getNames = () => {
        const names = activeTenders.map((t) => t.name);
        const namesArray = []

        names.forEach(n => {
            namesArray.push(
                { value: n, label: n }
            )
        })
        console.log(namesArray)

        setNewOptions(namesArray)
    }

    const handleNameChange = (newValue) => {
        setInputValue(newValue);

        setFormData({
            ...formData,
            name: newValue,
        });

        // Check if the length of the input is greater than or equal to 5
        setShowSuggestions(newValue.length >= 5);

        if (newValue.length >= 5) {
            setShowSuggestions(true)
        }
        else {
            setShowSuggestions(false)
        }
    };

    useEffect(() => {
        getNames()
    }, [setShowSuggestions])

    const options = [
        { value: 'signage', label: 'Signage' },
        { value: 'landscaping', label: 'Landscaping' },
        { value: 'structural steel', label: 'Structural Steel' },
        { value: 'carpentry', label: 'Carpentry' },
        { value: 'doors and windows', label: 'Doors & Windows' },
        { value: 'joinery', label: 'Joinery' },
        { value: 'metal work', label: 'Metal Work' },
        { value: 'painting', label: 'Painting' }
    ]

    const categoryOptions = [
        { value: 'residential', label: 'Residential' },
        { value: 'industrial', label: 'Industrial' },
        { value: 'commercial', label: 'Commercial' },
        { value: 'refurbishment', label: 'Refurbishment' },
        { value: 'health', label: 'Health' },
        { value: 'government', label: 'Government' },
        { value: 'education', label: 'Education' },
        { value: 'fitout', label: 'Defence' },
        { value: 'retail', label: 'Retail' },
        { value: 'civil', label: 'Civil' },
        { value: 'hospitality', label: 'Hospitality' },
        { value: 'other', label: 'Other' }
    ]

    const budgetOptions = [
        { value: '< $100k', label: '< $100k' },
        { value: '$100k - $250k', label: '$100k - $250k' },
        { value: '$250k - $500k', label: '$250k - $500k' },
        { value: '$500k - $1m', label: '$500k - $1m' },
        { value: '$1m - $2.5m', label: '$1m - $2.5m' },
        { value: '$2.5m - $5m', label: '$2.5m - $5m' },
        { value: '$5m - $10m', label: '$5m - $10m' },
        { value: '> $10m', label: '> $10m' }
    ]

    const stateOptions = [
        { value: 'nsw', label: 'New South Wales' },
        { value: 'qld', label: 'Queensland' },
        { value: 'vic', label: 'Victoria' },
        { value: 'sa', label: 'South Australia' },
        { value: 'wa', label: 'Western Australia' },
        { value: 'nt', label: 'Northern Territory' },
        { value: 'act', label: 'Australian Capital Territory' }
    ]

    const [isPrivate, setIsPrivate] = useState(null)

    const handlePrivateClick = () => {
        setIsPrivate(true)
    }

    const handlePublicClick = () => {
        setIsPrivate(false)
    }

    useEffect(() => {
        setFormData({
            ...formData,
            trades: selected
        })
        console.log(selected)
    }, [selected])

    useEffect(() => {
        console.log(formData.name)
    }, [formData.name])

    const formatCreateLabel = (inputValue) => `"${inputValue}"`;

    const addProjectToUser = async (tid) => {
        console.log(uid)
        try {
            const userDocRef = doc(db, 'users', uid);
            const docSnap = await getDoc(userDocRef); // Adding await here
            console.log(docSnap.data()); // Access data property to log document data

            await updateDoc(userDocRef, {
                tenders: arrayUnion(tid)
            });

            console.log('Added ', tid, ' to user ', uid);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const createTenderDocument = async () => {
        const docRef = await addDoc(collection(db, "tenders"), formData)
        const newDoc = doc(db, "tenders", docRef.id);

        addProjectToUser(docRef.id)

        await updateDoc(newDoc, {
            id: docRef.id
        });
    };

    const finalSubmit = () => {
        console.log(formData);
        createTenderDocument()
        console.log(formData)
        setFormData(sampleData)
        handleClose()
        showSuccessToast()
    }

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (value) => {
        setSelectedDate(value);
    };

    useEffect(() => {
        setFormData({
            ...formData,
            quotesDue: selectedDate
        })
        console.log(selectedDate)
    }, [selectedDate])

    return (
        <>
            <IconButton colorScheme='purple' borderRadius={'50%'} icon={<AddIcon />} onClick={handleOpen} />
            <Modal onClose={handleClose} isOpen={isOpen} isCentered position={'relative'}>
                <ModalOverlay />
                <ModalContent maxW="65%" minH="450px">
                    <ModalHeader >
                        <Heading size={'md'} textAlign={'center'}>New Project</Heading>
                    </ModalHeader>
                    <ModalBody align={'center'} >
                        <Stack >
                            {activeStep === 0 && <Box>

                                <Input placeholder={'Project Name'} w={'80%'} name={'name'} value={formData.name} onChange={handleInputChange} size={'lg'} />

                                <HStack w={'80%'}>
                                    <Input placeholder={'Address'} name={'address'} value={formData.address} onChange={handleInputChange} size={'lg'} mt={'2%'} w={'60%'} />
                                    <Input placeholder={'Suburb/Area'} name={'area'} value={formData.area} onChange={handleInputChange} size={'lg'} mt={'2%'} w={'40%'} />
                                </HStack>
                                <HStack w={'80%'} mt={'2%'} >
                                    <Box w={'30%'} >
                                        <ReactSelect placeholder='State' options={stateOptions} borderRadius={'5%'} height={'100%'} onChange={handleStateSelectChange} width={'100%'} />
                                    </Box>
                                    <Input placeholder='Postcode' w={'30%'} />
                                    <Box w={'40%'}>
                                        <ReactSelect placeholder='Category' options={categoryOptions} borderRadius={'5%'} height={'100%'} onChange={handleCategorySelectChange} />
                                    </Box>
                                </HStack>
                            </Box>}
                            {activeStep === 1 && <Box height={'100%'}>
                                <Textarea resize={'none'} minH={'200px'} placeholder='Describe This Project' value={formData.description} onChange={handleInputChange} name={'description'} />
                            </Box>}
                            {activeStep === 2 && <Box>
                                <Stack align={'center'}>
                                    <Box w={'75%'}>
                                        <CreatableSelect options={options} isMulti={true} formatCreateLabel={formatCreateLabel} placeholder={'Trades Involved'} onChange={handleSelectChange} />
                                        <Divider my={'2.5%'} />
                                        <Box>
                                            <HStack>
                                                <Button w={'50%'} onClick={handlePublicClick} bg={!isPrivate ? 'teal.500' : 'teal.400'}
                                                    color="white"

                                                    _hover={{ bg: 'teal.500' }}
                                                    {...(!isPrivate && {
                                                        transform: 'scale(0.98)',
                                                        borderColor: '#bec3c9',
                                                    })}>Public</Button>
                                                <Button w={'50%'} onClick={handlePrivateClick} bg={isPrivate ? 'teal.500' : 'teal.400'}
                                                    color="white"

                                                    _hover={{ bg: 'teal.500' }}
                                                    {...(isPrivate && {
                                                        transform: 'scale(0.98)',
                                                        borderColor: '#bec3c9',
                                                    })}>Private</Button>

                                                <ChakraTooltip hasArrow label='Private tenders require subcontractors to enter a password or request access, which you can grant or deny' bg='gray.50' color='black'>
                                                    <span>
                                                        <InfoIcon />
                                                    </span>
                                                </ChakraTooltip>
                                            </HStack>
                                            {isPrivate && <Input placeholder='Password' mt={'2.5%'} name={'password'} value={formData.password} onChange={handleInputChange}></Input>}
                                        </Box>
                                        <Divider my={'2.5%'} />
                                        <Box>
                                            <HStack w={'100%'}>
                                                <Box w={'50%'}>


                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={(date) => setSelectedDate(date)}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Choose Due Date"
                                                        className="custom-datepicker"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Box>
                                                <Box w={'50%'}>
                                                    <CreatableSelect options={budgetOptions} placeholder={'Budget'} w={'100%'} onChange={handleBudgetSelectChange} />
                                                </Box>
                                            </HStack>
                                        </Box>

                                    </Box>
                                </Stack>
                            </Box>}
                            {activeStep === 3 && <Box >
                                <Stack align={'center'} w={'100%'} height={'100%'} >
                                    {formData.name != '' && formData.budget != '' &&
                                        <Box w={'100%'} h={'100%'}>
                                            <Box w={'80%'} textAlign={'left'} >
                                                <Box>
                                                    <Heading size={'md'} pl={'5%'}>{formData.name}</Heading>
                                                    <Heading size={'sm'} pl={'5%'}>{formData.address} , {formData.area}</Heading>
                                                    <Divider my={'1.5%'} />
                                                    <Text pl={'5%'}>Quotes Due {formData.quotesDue.toString()}</Text>
                                                    <Divider my={'1.5%'} />
                                                    <Text pl={'5%'}>Budget of {formData.budget}</Text>
                                                    <Divider my={'1.5%'} />
                                                    <Text pl={'5%'} mb={'2.5%'}>{formData.trades.length} Trades Involved... { } </Text>
                                                </Box>
                                            </Box>
                                            <Button onClick={() => { finalSubmit() }} colorScheme='teal' w={'80%'}>Submit</Button>
                                        </Box>
                                    }
                                </Stack>

                            </Box>}

                        </Stack>
                    </ModalBody>
                    <ModalCloseButton />
                    <ModalFooter justifyContent={'center'}>
                        <IconButton bg={'purple.400'} onClick={() => { DecrementStep() }} mr={'2.5%'} icon={<ChevronLeftIcon />} _hover={{ bg: 'purple.500' }}></IconButton>

                        <Stepper size='lg' index={activeStep} colorScheme={'teal'} w={'80%'} >
                            {steps.map((step, index) => (
                                <Step key={index} onClick={() => setActiveStep(index)}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepDescription>{step.description}</StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                        <IconButton bg={'purple.400'} onClick={() => { IncrementStep() }} ml={'2.5%'} icon={<ChevronRightIcon />} _hover={{ bg: 'purple.500' }}></IconButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}


function AddProjectPopup({ userInfo, tenderList }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [tid, setID] = useState('')

    const handleIDChange = (event) => {
        setID(event.target.value);
    };


    const ref = doc(db, "users", userInfo.id);



    const joinProject = async () => {
        //console.log("UID: " + uid);
        //console.log("TID: " + tid);
        console.log("Successfully added user " + userInfo.id + " to project " + tid);
        await updateDoc(ref, {
            tenders: arrayUnion(tid)
        })

        const tenderDocRef = doc(db, 'tenders', tid);

        await updateDoc(tenderDocRef, {
            subcontractors: arrayUnion(userInfo.id)
        });
    }

    return (
        <>
            <IconButton colorScheme='blue' borderRadius={'50%'} icon={<AddIcon />} onClick={onOpen} />
            <Modal onClose={onClose} isOpen={isOpen} size={'xl'} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Tabs variant='soft-rounded' colorScheme='teal' size={'md'} align={'center'}>
                            <TabList>
                                <Tab>Join Project</Tab>
                                <Tab>Invitations</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel size={'lg'}>
                                    <ModalBody>
                                        <Stack>
                                            <Input placeholder='Project ID...' size='md' onChange={handleIDChange} ></Input>
                                            <Input placeholder='Password (if required)...'></Input>
                                            <br />
                                            <Button onClick={() => joinProject({ userInfo, tid })} colorScheme='teal' {...(tid == '' && { bg: 'gray.200' })}>Join</Button>
                                        </Stack>
                                    </ModalBody>
                                </TabPanel>
                                <TabPanel size={'lg'}>
                                    <ModalBody>
                                        <Text>Invitations feature coming soon!</Text>
                                    </ModalBody>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalFooter >
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

function HomePage() {
    const [allTenders, setAllTenders] = useState([]);
    const [userActiveTenders, setUserActiveTenders] = useState([]);
    const [userID, setUID] = useState(null);
    const isLoggedIn = useRef(false);
    const [count, setCount] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userRFIlist, setUserRFIlist] = useState([]);
    const [allRFIs, setAllRFIs] = useState([]);
    const [userRFIs, setRFIs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingTenders, setLoadingTenders] = useState(true);
    const [loadingRFIs, setLoadingRFIs] = useState(true);
    const [loadingUserData, setLoadingUserData] = useState(true);
    const [userTendersArray, setUserTendersArray] = useState([])
    const [authStateSettled, setAuthStateSettled] = useState(false);
    const [loginCount, setLoginCount] = useState(0);

    const [userInfo, setUserInfo] = useState({});

    const [accountType, setAccountType] = useState('')

    const stripe = require('stripe')('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');


    const fetchAllTenders = async () => {
        try {
            setLoadingTenders(true);
            const q = query(collection(db, "tenders"), orderBy("quotesDue", "asc"));

            const querySnapshot = await getDocs(q);
            const tenders = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllTenders(tenders);
            //console.log("All Tenders fetched successfully");
            //console.log("Updated All Tenders state:", allTenders);
            setLoadingTenders(false);
            return true;
        } catch (error) {
            console.error("Error fetching All Tenders:", error);
            setLoadingTenders(false);
            return false;
        }
    };

    const fetchAllRFIs = async () => {
        try {
            setLoadingRFIs(true);
            const querySnapshot = await getDocs(collection(db, "RFIs"));
            const rfis = querySnapshot.docs.map((doc) => {
                const tenderId = doc.data().tender;
                const tender = allTenders.find((tender) => tender.id === tenderId);

                return {
                    id: doc.data().id,
                    progress: doc.data().progress,
                    query: doc.data().query,
                    resolved: doc.data().resolved,
                    responses: doc.data().responses,
                    status: doc.data().status,
                    officialResponse: doc.data().officialResponse,
                    tender: tender,
                    urgency: doc.data().urgency,
                    trade: doc.data().trade,
                    subcontractor: doc.data().subcontractor,
                    contractor: doc.data().contractor,
                    updates: doc.data().updates,
                    contractorPendingResolved: doc.data().contractorPendingResolved,
                    subcontractorPendingResolved: doc.data().subcontractorPendingResolved,
                    subcontractorCompany: doc.data().subcontractorCompany
                };
            });
            setAllRFIs(rfis);
            //console.log("All RFIs fetched successfully");
            //console.log("Updated All RFIs state:", allRFIs);
            setLoadingRFIs(false);
            return true;
        } catch (error) {
            console.error("Error fetching All RFIs:", error);
            setLoadingRFIs(false);
            return false;
        }
    };
    console.log(userInfo)


    //LISTENER FOR USER RFI CHANGES AND LOADING USER RFIS
    useEffect(() => {
        const q = query(collection(db, "RFIs"), where('subcontractor', "==", '30SAP8nRtrdARhoLterGyW5SZJo2')); // Assuming accountType and userID are defined somewhere

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const updatedRfis = [];
            querySnapshot.forEach((doc) => {
                updatedRfis.push({ id: doc.id, ...doc.data() });
            });

            updatedRfis.sort((a, b) => b.updates[b.updates.length - 1].date - a.updates[a.updates.length - 1].date);
            console.log('Sorted Array: ', updatedRfis)

            setUserRFIlist(updatedRfis);
        });

        // Cleanup function
        return () => unsubscribe();
    }, []);


    const fetchUserActiveTenders = async (allT) => {
        console.log(allT)
        if (userInfo.tenders) {
            const t = userInfo.tenders
            console.log(t)
            setUserTendersArray(t);
            const items = allT.filter((tender) => t.includes(tender.id));
            console.log(items)
            setUserActiveTenders(items);
        }

    };

    onAuthStateChanged(auth, async (user) => {
        if (user) {

            if (loginCount === 0) {
                setLoggedIn(true);
                setAuthStateSettled(true);
                setLoginCount((prevCount) => prevCount + 1)

                console.log('signing in: ', user.email)
                const uid = user.uid;
                setUID(uid)

                const userRef = doc(db, 'users', uid);
                const docSnapshot = await getDoc(userRef);

                if (docSnapshot.exists()) {
                    const accType = docSnapshot.data().accountType;

                    setUserInfo({
                        id: uid,
                        firstName: docSnapshot.data().firstName,
                        lastName: docSnapshot.data().lastName,
                        company: docSnapshot.data().company,
                        accountType: docSnapshot.data().accountType,
                        subscription: docSnapshot.data().subscription,
                        tenders: docSnapshot.data().tenders,
                        favouriteTenders: docSnapshot.data().favouriteTenders,
                        email: docSnapshot.data().email
                    })

                    console.log(accType)
                    setAccountType(accType)
                }
            }
        } else {
            setLoggedIn(false);
        }
    });

    const [subscriptionActive, setSubscriptionActive] = useState(true)

    useEffect(() => {

        const fetchSubscription = async () => {
            try {
                const subscription = await stripe.subscriptions.retrieve(
                    userInfo.subscription
                );
                console.log(subscription)
                if (subscription.status !== 'active') {
                    setSubscriptionActive(false)
                    console.log('subscription active')
                }
                else {
                    setSubscriptionActive(true)
                }
            } catch (error) {
                console.error('Error fetching subscription:', error);
            }
        };
        fetchUserActiveTenders(allTenders);

        fetchSubscription();
    }, [userInfo])



    useEffect(() => {
        //initially load all tenders
        fetchAllTenders();
    }, [])

    useEffect(() => {
        if (authStateSettled) {
            // Initiate data fetching only when the authentication state is settled
            fetchAllRFIs();
        }
    }, [allTenders, authStateSettled]);



    return (
        <ChakraProvider theme={theme}>
            <Navbar />
            {subscriptionActive === true ? (
                <>
                    {accountType === "subcontractor" ? (
                        <Box bg={'white'}>
                            <Box className='row'>
                                <Box className='left-column' backgroundColor={'gray.50'}>
                                    <Box minHeight={'85vh'}>
                                        <Box >
                                            <RFIList messages={userRFIlist} userInfo={userInfo} userTenders={userActiveTenders} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='right-column' backgroundColor={'gray.50'}>
                                    <Box minHeight="85vh">
                                        <Tabs variant="soft-rounded" colorScheme="teal" size="md" align="center" height="100%">
                                            <TabList>
                                                <Tab>My Tenders</Tab>
                                                <Box pr="5%" pl="5%">
                                                    <AddProjectPopup userInfo={userInfo} tenderList={allTenders} />
                                                </Box>
                                                <Tab>All Tenders</Tab>
                                                <Box></Box>
                                            </TabList>
                                            <TabPanels height="100%">
                                                <TabPanel height="100%">
                                                    {userActiveTenders.length === 0 ? (
                                                        <Heading mt={'1%'} color={'red.700'} size={'md'}>You haven't joined any tenders yet - use the button above or the "All Tenders" tab to join your first project. </Heading>
                                                    ) : (
                                                        <TenderList tenders={userActiveTenders} userList={true} userInfo={userInfo} itemsPerPage={5} />
                                                    )}
                                                </TabPanel>
                                                <TabPanel height="100%">
                                                    <TenderList tenders={allTenders} userList={false} userInfo={userInfo} itemsPerPage={5} />
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>





                    ) : accountType === "contractor" ? (
                        <Box bg={'gray.50'} minHeight={'85vh'} mx={'1%'} my={'1%'} borderRadius={'1%'} h={'100%'}>
                            <Stack h={'100%'}>

                                <Box mt={'1%'}>
                                    <Tabs variant='line' colorScheme='teal' size={'md'} align={'center'}>
                                        <TabList>
                                            <Tab mx={'2%'}>Projects</Tab>
                                            <NewProjectPopup activeTenders={allTenders} uid={userInfo.id} userInfo={userInfo} />
                                            <Tab mx={'2%'}>Analytics</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <Box>
                                                    {userActiveTenders.length === 0 ? (
                                                        <Box>
                                                            <Heading mt={'1%'} color={'gray.700'}>You don't have any projects yet</Heading>
                                                            <Text mt={'1%'} fontSize={'lg'} color={'gray.700'}>Use the button above to upload your first tender.</Text>
                                                        </Box>
                                                    ) : (
                                                        <ProjectList projects={userActiveTenders} uid={userInfo.id} allRFIs={allRFIs} userInfo={userInfo} />
                                                    )}
                                                </Box>
                                            </TabPanel>
                                            <TabPanel h={'100%'}>
                                                <Box h={'100%'}>
                                                    {/*
                                                    <HStack h={'100%'} justifyContent={'space-between'}>
                                                        <Stack h={'100%'} w={'15%'} spacing={5}>
                                                            <Box bg={'white'} w={'100%'} h={'25%'} borderRadius={'4%'} borderColor={'purple.200'} borderStyle={'solid'} borderWidth={'1px'}>
                                                                <Stack justifyContent={'space-between'} h={'100%'}>
                                                                    <Heading mt={'10%'} fontSize={'400%'} color={'teal.400'}>8</Heading>
                                                                    <Text mb={'2%'} fontSize={'lg'}>Active Tenders</Text>
                                                                </Stack>
                                                            </Box>
                                                            <Box bg={'white'} w={'100%'} h={'25%'} borderRadius={'4%'} borderColor={'purple.200'} borderStyle={'solid'} borderWidth={'1px'}>
                                                                <Stack justifyContent={'space-between'} h={'100%'}>
                                                                    <Heading mt={'10%'} fontSize={'400%'} color={'teal.400'}>19</Heading>
                                                                    <Text mb={'2%'} fontSize={'lg'}>Outstanding RFIs</Text>
                                                                </Stack>
                                                            </Box>
                                                            <Box bg={'white'} w={'100%'} h={'25%'} borderRadius={'4%'} borderColor={'purple.200'} borderStyle={'solid'} borderWidth={'1px'}>
                                                                <Stack justifyContent={'space-between'} h={'100%'}>
                                                                    <Heading mt={'10%'} fontSize={'400%'} color={'teal.400'}>92%</Heading>
                                                                    <Text mb={'2%'} fontSize={'lg'}>Response Rate</Text>
                                                                </Stack>
                                                            </Box>
                                                            <Box bg={'white'} w={'100%'} h={'25%'} borderRadius={'4%'} borderColor={'purple.200'} borderStyle={'solid'} borderWidth={'1px'}>
                                                                <Stack justifyContent={'space-between'} h={'100%'}>
                                                                    <Heading mt={'10%'} fontSize={'400%'} color={'teal.400'}>38%</Heading>
                                                                    <Text mb={'2%'} fontSize={'lg'}>Awarded Rate</Text>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                        <Box bg={'white'} w={'80%'} h={'90vh'} borderRadius={'4%'} borderColor={'purple.200'} borderStyle={'solid'} borderWidth={'1px'}>
                                                            <CustomChart data={data} />
                                                        </Box>
                                                    </HStack>
                                                    */}
                                                    <Heading mt={'1%'} color={'gray.700'}>Analytics feature coming soon...</Heading>

                                                </Box>

                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Box>
                                )
                            </Stack>
                        </Box>
                    ) : (
                        <Box>
                            <Heading size={'xl'} color={'gray.700'} mt={'15%'}>
                                Log In or Sign Up to access your dashboard
                            </Heading>
                        </Box>
                    )}
                </>
            ) : (
                <Box>
                    <Stack mt={'2.5%'}>
                        <Heading size={'xl'} color={'gray.700'}>
                            Your subscription is <Text as="span" color="red.500">inactive</Text>
                        </Heading>
                        <Text fontSize={'lg'} color={'gray.700'} mt={'1%'}>Contact support to reactivate your account...</Text>
                        <a href="mailto:jack@tenderpulse.au">
                            <Text mt={'1%'} style={{ textDecoration: 'underline' }} >jack@tenderpulse.au</Text>
                        </a>

                    </Stack>
                </Box>
            )}
        </ChakraProvider>
    );



}

export default HomePage;