import React from "react";

import {useState} from "react";

import { Icon, Box, Flex, Button, Text } from "@chakra-ui/react";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";




export default function ExpandableText({ query, maxChars }) {
    const [showFullText, setShowFullText] = useState(false);

    const truncatedText = showFullText ? query : query.slice(0, maxChars);

    return (
        <Box>
            <Flex justifyContent="space-between" alignItems="center">
                <Text>{truncatedText}</Text>
            </Flex>
            {query.length > maxChars && (
                <Flex justifyContent="flex-end">
                    <Button
                        variant="link"
                        onClick={() => setShowFullText(!showFullText)}
                        textDecoration="underline"
                    >
                        {showFullText ? (
                            <>
                                Show Less <Icon as={ChevronUpIcon} />
                            </>
                        ) : (
                            <>
                                Show More <Icon as={ChevronDownIcon} />
                            </>
                        )}
                    </Button>
                </Flex>
            )}
        </Box>
    );
};