import React, { useState, useEffect, useRef } from 'react';

import './App.css';
import Navbar from './navbar.jsx';
import Progress_bar from './progress';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { MdMoreHoriz, MdEdit, MdRefresh } from 'react-icons/md'
import {
  Card, Image, CardBody, Text, CardFooter,
  Button, Stack, Heading, CircularProgress,
  SimpleGrid, CardHeader, useColorModeValue,
  Box, Flex, Center, Accordion, AccordionItem,
  AccordionButton, AccordionPanel, Container, Collapse,
  Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
  DrawerOverlay, DrawerContent, DrawerCloseButton, InputGroup, InputRightElement, InputLeftElement, Switch,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay, useToast,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { MoonIcon, SunIcon, ChevronDownIcon, AddIcon, SettingsIcon, SearchIcon, DeleteIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme.js';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from './firebase';
import { db } from './firebase';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import RFIList from './rfiList.jsx';
import SidePopup from './sidePopup.jsx';
import Pagination from './Pagination.jsx';

function timestampToDDMM(timestamp) {
  const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);

  // Create a new Date object from the milliseconds
  const date = new Date(milliseconds);

  // Extract the day and month parts
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');

  // Return the formatted string
  return `${day}/${month}`;
}

const TenderList = ({ tenders, userList, userInfo, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [showClosed, setShowClosed] = useState(false);
  const [filteredTenders, setFilteredTenders] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [chosenTender, setChosenTender] = useState('')

  const toast = useToast()

  const showToast = () => {
      toast({
          title: 'Project Removed',
          description: 'Refresh to update...',
          duration: 3000,
          isClosable: true,
          status: 'success',
          position: 'top'
      })
  }

  useEffect(() => {
    // Filter tenders based on search query and switch status
    const filtered = tenders.filter(
      (t) =>
        t.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (showClosed || t.status !== 'Closed')
    );
    setFilteredTenders(filtered);
    setCurrentPage(1); // Reset current page when filter changes
  }, [tenders, searchQuery, showClosed]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setShowClosed(event.target.checked);
  };

  const handleRemove = async () => {
    //console.log('removed ', tenderID, ' user ', userInfo.id);
    console.log(chosenTender)

    const userDocRef = doc(db, 'users', userInfo.id);
    const tenderDocRef = doc(db, 'tenders', chosenTender)

    await updateDoc(userDocRef, {
      tenders: arrayRemove(chosenTender)
    });

    await updateDoc(tenderDocRef, {
      subcontractors: arrayRemove(userInfo.id)
    })

    showToast();
    // Your handleRemove logic
  };

  const handleRemoveButtonClick = async (tenderID) => {
    // Your handleRemoveButtonClick logic
    onOpen();
    setChosenTender(tenderID)
  };

  const totalPages = Math.ceil(filteredTenders.length / itemsPerPage);

  const sliceData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredTenders.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Box>
      {/* Search bar */}
      <Box>
        <HStack>
          <InputGroup bg={'whiteAlpha.400'} w={'75%'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input
              placeholder='Search by tender name'
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </InputGroup>
          <Text fontSize={'sm'}>Show Closed</Text>
          <Switch
            isChecked={showClosed}
            onChange={handleSwitchChange}
            colorScheme='teal'
          />
        </HStack>
      </Box>

      {/* Render tenders */}
      {sliceData().map((t) => (
        <Box key={t.id}>
          {/* Render tender card */}
          <Card mt={'2%'} bg={'white'}>
            <CardBody w={'100%'}>
              <Stack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'lg'} fontWeight={'bold'} textAlign={'left'}>
                    {t.name}
                  </Text>
                  {userList && (
                    <Button
                      size={'sm'}
                      bg={'red.400'}
                      color={'white'}
                      _hover={{ bg: 'red.500' }}
                      onClick={() => handleRemoveButtonClick(t.id)}
                    >
                      Remove
                    </Button>
                  )}
                </HStack>
                <HStack>
                  <Text>Quotes Due {timestampToDDMM(t.quotesDue)}</Text>
                </HStack>
                <HStack w={'100%'} justifyContent={'space-between'}>
                  <Text>{t.status}</Text>
                  <SidePopup tender={t} isJoined={userList} userInfo={userInfo} />
                </HStack>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      ))}

      {/* Pagination controls */}
      {filteredTenders.length > itemsPerPage && (
        <Box mt={4} textAlign='center'>
          {[...Array(totalPages)].map((_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? 'solid' : 'outline'}
              colorScheme='teal'
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
        </Box>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Remove Tender
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to remove this tender? You can rejoin at any time...
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => { onClose(); handleRemove()}} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>

  );
};

export default TenderList;