import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from './firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";


import { ChakraProvider, Input, Stack, Box, Button, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Heading, InputGroup, InputRightElement, useToast, createStandaloneToast, HStack, Image, Center } from '@chakra-ui/react';
import { isCompositeComponent } from 'react-dom/test-utils';



const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [show, setShow] = React.useState(false)
    const [password2, setPassword2] = useState('');
    const [accountType, setAccountType] = useState('');
    const handleClick = () => setShow(!show)

    const toast = useToast()

    const passwordErrorToast = () => {
        toast({
            title: 'Passwords Do Not Match',
            description: 'Please ensure your confirmation password matches. ',
            duration: 3000,
            isClosable: true,
            status: 'error',
            position: 'top'
        })
    }

    const handleContractorButtonClick = () => {
        setAccountType('contractor')
    }

    const handleSubcontractorButtonClick = () => {
        setAccountType('subcontractor')
    }

    useEffect(() => {
        console.log('Account type is ', accountType)
    }, [accountType])

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/Dashboard")
                console.log(user);

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    const onSubmit = async (e) => {
        e.preventDefault()

        const docRef = doc(db, "leads", "Potential Leads");

        await updateDoc(docRef, {
            emails: arrayUnion(email)
        })
        redirectToStripeCheckout()
    }

    const redirectToStripeCheckout = () => {
        // Redirect the user to the Stripe checkout link
        if (accountType == 'contractor') {
            window.location.href = 'https://buy.stripe.com/bIYg1WfVYcg9bkI3cc';
        }
        else if (accountType == 'subcontractor') {
            window.location.href = 'https://buy.stripe.com/test_00g6qV6nMfRhble001';
        }
    };

    return (
        <ChakraProvider>
            <Box maxheight={'100vh'} >

                <Center>
                    <Image src={'/logo-no-background.svg'} boxSize={'30%'} mt={'4%'} mb={'2%'} />
                </Center>
                <Tabs variant='enclosed-colored' colorScheme='teal' size={'md'} align={'center'}  >
                    <TabList>
                        <Tab>Login</Tab>

                        <Tab h={'50%'} >Sign Up</Tab>
                        <Box>
                        </Box>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Stack maxW={'40%'} mt={'2%'}>
                                <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)}></Input>
                                <InputGroup size='md'>
                                    <Input
                                        pr='4.5rem'
                                        type={show ? 'text' : 'password'}
                                        placeholder='Enter password'
                                        onChange={(e) => setPassword(e.target.value)}></Input>
                                    <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                                            {show ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <Button onClick={onLogin} colorScheme='teal'>Log In</Button>
                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <Stack maxW={'40%'} mt={'2%'} >
                                <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)}></Input>
                                <HStack w={'100%'}>
                                    <Button w="50%"
                                        bg={accountType === 'subcontractor' ? 'teal.500' : 'teal.400'}
                                        color="white"
                                        onClick={handleSubcontractorButtonClick}
                                        _hover={{ bg: 'teal.500' }}
                                        {...(accountType === 'subcontractor' && {
                                            transform: 'scale(0.98)',
                                            borderColor: '#bec3c9',
                                        })}
                                    >I am a Subcontractor</Button>
                                    <Button w="50%"
                                        bg={accountType === 'contractor' ? 'teal.500' : 'teal.400'}
                                        color="white"
                                        onClick={handleContractorButtonClick}
                                        _hover={{ bg: 'teal.500' }}
                                        {...(accountType === 'contractor' && {
                                            transform: 'scale(0.98)',
                                            borderColor: '#bec3c9',
                                        })}>I am a Head Contractor/Builder</Button>

                                </HStack>

                                <Center>
                                    <Button onClick={onSubmit} w={'100%'} mt={'2%'} bg={'teal.400'} color={'white'} _hover={{ bg: 'teal.500' }}>Sign Up</Button>

                                </Center>
                            </Stack>
                        </TabPanel>

                    </TabPanels>

                </Tabs>


            </Box>
        </ChakraProvider>
    )
}

export default Login;