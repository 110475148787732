'use client'
import React, { useRef } from 'react'

import { UserContext } from "./contexts/user.context";
import { useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase';
import { MdMoreHoriz, MdEdit, MdRefresh } from 'react-icons/md'
import { ChakraProvider, Divider, Textarea, Toast } from '@chakra-ui/react'
import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Grid, GridItem, useToast
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons'
import theme from './theme';
import { Chrono } from 'react-chrono';
import { SendUpdate } from './backend';
import { FaStar, FaRegStar } from 'react-icons/fa';

export default function UpdatesTimeline({ RFI, account }) {
    const renderHeader = (read) => {
        // Conditionally render the icon based on the boolean value
        return read ? <FaStar color="gold" /> : <FaRegStar />;
      };

    if (RFI) {
        const path = 'test'

        const formatDate = (timestamp) => {
            const date = timestamp.toDate()

            return date
        }

        if (account == "subcontractor") {
            console.log('From RFI: ', RFI.updates)
            const RFIUpdates = RFI.updates;

            const orderedUpdates = RFIUpdates.sort((a, b) => b.date.toMillis() - a.date.toMillis())

            console.log('Ordered: ', orderedUpdates)
            const updates = RFI.updates.map(u => ({
                cardTitle: u.company,
                cardDetailedText: u.text,
                date: formatDate(u.date),
                cardSubtitle: u.read == true ? 'Seen' : '',
                position: u.accountType === 'subcontractor' ? 'RIGHT' : 'LEFT',
                read: u.read
            }));
            console.log('Generated: ', updates)
            return (
                <Box>
                    <Chrono items={updates} mode={'VERTICAL'} titleDateFormat={'DD MMM'} scrollable cardHeight={'50%'} >

                    </Chrono>
                </Box>
            )
        }
        else if (account == "contractor") {

            const updates = RFI.updates.map(u => ({
                cardTitle: u.company,
                cardDetailedText: u.text,
                date: formatDate(u.date),
                side: u.accountType === 'contractor' ? 'right' : 'left'

            }));
            return (

                <Box>
                    <Chrono items={updates} mode={'VERTICAL'} titleDateFormat={'DD MMM'} cardHeight={'100'} />

                </Box>
            )
        }
    }
}