import Navbar from './navbar.jsx'
import {
  Card, Image, CardBody, Text, CardFooter,
  Button, Stack, Heading, CircularProgress,
  SimpleGrid, CardHeader, useColorModeValue,
  Box, Flex, Center, Accordion, AccordionItem,
  AccordionButton, AccordionPanel, Container, Collapse
} from '@chakra-ui/react'
// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import React, { useState, useEffect, useRef, useContext } from 'react';

function StripeSubscriptionButton() {
  const redirectToStripeCheckout = () => {
    // Redirect the user to the Stripe checkout link
    window.location.href = 'https://buy.stripe.com/test_fZedTneUigVl0GA144';
  };

  // This function will be called when the user is redirected back after subscribing
  const handleSuccessSubscription = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = urlParams.get('session_id');
    if (checkoutSessionId) {
      // If the session_id query parameter is present, it means the user has subscribed successfully
      console.log('Subscription successful! Checkout Session ID:', checkoutSessionId);
      // Execute your code here with the checkoutSessionId
    }
  };

  React.useEffect(() => {
    handleSuccessSubscription();
  }, []);

  return (
    <button onClick={redirectToStripeCheckout}>Subscribe via Stripe</button>
  );
}



function SettingsPage() {
  
  return(
    <Box>
      <Heading>Test Page</Heading>
      <StripeSubscriptionButton />
    </Box>
  )
  
}

export default SettingsPage;