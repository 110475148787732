// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtPaR3sC3H3jyqKC5nCawXx5xPqqDKAsk",
  authDomain: "tenderpulse-fe8e4.firebaseapp.com",
  projectId: "tenderpulse-fe8e4",
  storageBucket: "tenderpulse-fe8e4.appspot.com",
  messagingSenderId: "375387901269",
  appId: "1:375387901269:web:530f792e6dc059d4eb73dc",
  measurementId: "G-TXZ8J8NPFR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
export const db = getFirestore(app);
const analytics = getAnalytics(app);