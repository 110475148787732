import React, { useState, useEffect, useRef } from 'react';

import './App.css';
import Navbar from './navbar.jsx';
import Progress_bar from './progress';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { MdMoreHoriz, MdEdit, MdRefresh } from 'react-icons/md'
import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Divider, useToast
} from '@chakra-ui/react'
import { MoonIcon, SunIcon, ChevronDownIcon, AddIcon, SettingsIcon } from '@chakra-ui/icons'
import { ChakraProvider } from '@chakra-ui/react'

import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from './firebase';
import { db } from './firebase';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import RFIList from './rfiList.jsx';
import ExpandableText from './ExpandableText.jsx';
import theme from './theme.js';

function SidePopup({ tender, isJoined, userInfo }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [showTrades, setShowTrades] = useState(false)
    const [t, setT] = useState(null)
    const [userID, setUserID] = useState('');
    var referenceID = '';

    const toast = useToast()

    const showToast = () => {
        toast({
            title: 'Success',
            description: 'You have joined this project',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }

    useEffect(() => {
        console.log(userID)
    }, [userID])

    useEffect(() => {
        setT(tender);
        //console.log(messages)
    }, [tender])

    useEffect(() => {
        //console.log(t)
    }, [t])

    const handleJoin = async (tenderID) => {
        console.log(userInfo.id, ' joined ', tenderID);
        const userDocRef = doc(db, 'users', userInfo.id);
        const tenderDocRef = doc(db, 'tenders', tenderID);

        await updateDoc(tenderDocRef, {
            subcontractors: arrayUnion(userInfo.id)
        });

        await updateDoc(userDocRef, {
            tenders: arrayUnion(tenderID)
        });
        showToast();
    }

    const handleShowTrades = () => {
        setShowTrades(!showTrades)
    }

    return (
        < ChakraProvider theme={theme}>
            {tender &&
                <Box>
                    <Button ref={btnRef} colorScheme='teal' onClick={onOpen} size='sm' ml='auto'>More</Button>
                    <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef} size='sm'>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>
                                <Heading size={'lg'}>{tender.name}</Heading>
                                <Heading size={'xs'} mt={'2%'}>Project ID: {tender.id}</Heading>
                                {!isJoined && <Box justifyContent={'center'} w={'100%'}>
                                    <Button colorScheme='teal' size={'lg'} w={'100%'} mt={'10%'} onClick={() => handleJoin(tender.id)}>Join This Project</Button>
                                </Box>
                                }
                            </DrawerHeader>
                            <DrawerBody>
                                <Divider />
                                <Text fontSize={'lg'} fontWeight={'semibold'} mt={'5%'} textDecoration={'underline'}>Description</Text>
                                <Box my={'5%'} minH={'15%'} pl={'5%'}>
                                    {tender.description == '' ? <Text>
                                        No description has been provided
                                    </Text> : <ExpandableText query={tender.description} maxChars={200} />
                                    }
                                </Box>


                                <Divider />
                                <Text fontSize={'lg'} fontWeight={'semibold'} mt={'5%'} textDecoration={'underline'}>Trades</Text>

                                {!showTrades && <Box minH={'15%'}>
                                    <HStack w={'100%'} justifyContent={'space-between'}>
                                        <Text my={'5%'} size={'md'} pl={'5%'}>{tender.trades && tender && tender.trades.length} trades involved </Text>
                                        <Button variant={'link'} size={'md'} onClick={handleShowTrades}>Show</Button>
                                    </HStack>
                                </Box>
                                }
                                {showTrades && <Box>
                                    <HStack w={'100%'} justifyContent={'space-between'}>
                                        <Flex align="flex-start" direction="column" w="90%">
                                            <Box ml={'5%'} mt={'5%'}>
                                                <ul>
                                                    {tender.trades.map(project => (
                                                        <li>{project}</li>
                                                    ))}
                                                </ul>
                                            </Box>
                                        </Flex>
                                        <Button variant={'link'} size={'md'} onClick={handleShowTrades} my={showTrades && '5%'} alignSelf="flex-start">Hide</Button>
                                    </HStack>
                                </Box>
                                }
                                <Divider />
                                <Box minH={'15%'}>
                                    <Text fontSize={'lg'} fontWeight={'semibold'} mt={'5%'} textDecoration={'underline'}>Contractor</Text>

                                    {tender.contractors && tender.contractors.map(contractor => (
                                        <Box mt={'5%'} ml={'5%'}>
                                            <Text fontSize={'lg'}>{contractor.company}</Text>
                                            <Text fontSize={'md'} pl={'5%'}>{contractor.firstName} {contractor.lastName}</Text>
                                            <Box pl={'5%'} fontSize={'md'}>
                                                <a href={`mailto:${contractor.email}`}>{contractor.email}</a>
                                            </Box>
                                        </Box>
                                    ))}

                                </Box>
                                <Divider />
                                <Box minH={'15%'}>
                                    <Text fontSize={'lg'} fontWeight={'semibold'} mt={'5%'} textDecoration={'underline'}>Details</Text>
                                    <Text fontSize={'lg'} pl={'5%'}>{tender.address}</Text>
                                    <Text fontSize={'lg'} pl={'5%'}>{tender.area}</Text>
                                    <Text fontSize={'lg'} pl={'5%'}>Budget: {tender.budget}</Text>
                                </Box>
                                <Divider />
                            </DrawerBody>
                            <DrawerFooter>
                                {/*<Button colorScheme={'green'} variant={'ghost'} mr={'3'} onClick={onClose}>Close</Button>*/}
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Box>
            }

        </ChakraProvider >

    )
}

export default React.memo(SidePopup);