import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { ChakraProvider, Input, Stack, Box, Button, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Heading, InputGroup, InputRightElement, useToast, createStandaloneToast, HStack, Image } from '@chakra-ui/react';
import theme from './theme';
import { auth, db } from './firebase';
import { doc, setDoc } from "firebase/firestore";


function ConfirmationPage() {
    const navigate = useNavigate();


    const [sessionID, setSessionID] = useState(null)
    const stripe = require('stripe')('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');

    const [userDetails, setUserDetails] = useState(null)
    const [show, setShow] = React.useState(false)

    const handleClick = () => setShow(!show)

    const [paid, setPaid] = useState(false)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [subscription, setSubscription] = useState('');
    const [password2, setPassword2] = useState('');
    const [accountType, setAccountType] = useState('');

    const toast = useToast()

    const passwordErrorToast = () => {
        toast({
            title: 'Passwords Do Not Match',
            description: 'Please ensure your confirmation password matches. ',
            duration: 3000,
            isClosable: true,
            status: 'error',
            position: 'top'
        })
    }

    useEffect(() => {
        // Function to extract query parameters from the URL
        const getParameterByName = (name, url) => {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };

        // Get the Checkout Session ID from the URL query parameters
        const checkoutSessionId = getParameterByName('session_id');

        // Perform actions based on the Checkout Session ID
        if (checkoutSessionId) {
            // You can perform any actions you need here, such as updating your database,
            // sending confirmation emails, or displaying a success message to the user
            setSessionID(checkoutSessionId)
            console.log('Checkout Session ID:', checkoutSessionId);
        } else {
            console.log('No Checkout Session ID found in the URL');
        }
    }, []);

    const fetchSessionDetails = async (sessionId) => {
        try {
            // Make an HTTP GET request to the Stripe API endpoint
            const session = await stripe.checkout.sessions.retrieve(sessionID);// Replace with your actual endpoint
            return session // Return the data received from the Stripe API
        } catch (error) {
            // Handle any errors that occur during the request
            console.error('Error fetching session details:', error);
            return null; // Return null or handle the error as needed
        }
    };

    useEffect(() => {
        // Function to fetch session details from your server
        // Fetch session details when the component mounts
        if (sessionID) {
            fetchSessionDetails(sessionID)
                .then(sessionDetails => {
                    if (sessionDetails) {
                        // Handle the session details, e.g., display to the user
                        console.log('Session Details:', sessionDetails);


                        // Check the status of the payment intent
                        if (sessionDetails.payment_status === 'paid') {
                            // Payment was successful
                            console.log('Payment was successful!');
                            setPaid(true)
                        } else  {
                            // Payment failed or was declined
                            console.log('Payment failed or was declined.');
                            setPaid(false)
                        } 


                        const [fn, ln] = sessionDetails.customer_details.name.split(" ");
                        setFirstname(fn);
                        setLastname(ln);


                        setEmail(sessionDetails.customer_details.email)

                        setCompany(sessionDetails.custom_fields[0].text.value)

                        setSubscription(sessionDetails.subscription)

                        if (sessionDetails.payment_link == 'plink_1OiYdsFX6mvrslc36imv86Xh') {
                            setAccountType('contractor')
                        }
                        else {
                            setAccountType('subcontractor')
                        }
                    } else {
                        console.log('Session details not available');
                    }
                });
            console.log(sessionID)
        }
    }, [sessionID]);


    



    const onSubmit = async (e) => {
        e.preventDefault()

        if (password == password2) {
            await createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log(user.uid);
                    const uid = user.uid;

                    setDoc(doc(db, "users", uid), {
                        firstName: firstName,
                        lastName: lastName,
                        company: company,
                        email: email,
                        accountType: accountType,
                        rfis: [],
                        tenders: [],
                        subscription: subscription,
                        favouritedTenders: []
                    })

                    navigate("/Login")

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    // ..
                });
        }
        else {
            passwordErrorToast();
        }
    }


    return (
        <ChakraProvider theme={theme}>
            {paid ? 
            (<Stack alignItems={'center'} w={'100%'} >
                <Image src={'/logo-no-background.svg'} boxSize={'30%'} mt={'4%'} />

                {/* Your confirmation page content */}

                <Text fontSize={'lg'} mt={'5%'}>Thanks for Joining! Finalise your details below to create your account</Text>
                <Stack mt={'2%'} w={'40%'} >
                    <HStack>
                        <Input placeholder='First Name' onChange={(e) => setFirstname(e.target.value)} value={firstName}></Input>
                        <Input placeholder='Last Name' onChange={(e) => setLastname(e.target.value)} value={lastName}></Input>
                    </HStack>

                    <Input placeholder='Company' onChange={(e) => setCompany(e.target.value)} value={company}></Input>
                    <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)} value={email}></Input>

                    <InputGroup size='md'>
                        <Input
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            placeholder='Enter password'
                            onChange={(e) => setPassword(e.target.value)}></Input>

                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <InputGroup size='md'>
                        <Input
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            placeholder='Confirm Password'
                            onChange={(e) => setPassword2(e.target.value)}></Input>

                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Button onClick={onSubmit} colorScheme='teal'>Sign Up</Button>
                </Stack>
            </Stack>

             ) : ( 
                <Stack mt={'2.5%'}>
                    <Heading size={'xl'} color={'gray.700'}>
                        Payment <Text as="span" color="red.500">unsuccessful</Text>
                    </Heading>
                    <Text fontSize={'lg'} color={'gray.700'} mt={'1%'}>Contact support if you believe this to be a mistake</Text>
                    <a href="mailto:support@tenderpulse.com.au">
                        <Text mt={'1%'} style={{ textDecoration: 'underline' }} >support@tenderpulse.com.au</Text>
                    </a>

                </Stack>
             )}
    

        </ChakraProvider>
    );
}

export default ConfirmationPage;
