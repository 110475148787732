import {
    Card, Image, CardBody, Text, CardFooter,
    Button, Stack, Heading, CircularProgress,
    SimpleGrid, CardHeader, useColorModeValue,
    Box, Flex, Center, Accordion, AccordionItem,
    AccordionButton, AccordionPanel, Container, Collapse,
    Tabs, TabList, TabPanels, Tab, TabPanel, Icon, IconButton,
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Input, HStack, Drawer, DrawerBody, DrawerFooter, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Grid, GridItem, useToast, ChakraProvider, FormControl, FormLabel, Textarea, Link
} from '@chakra-ui/react'
import './animation.css'
import { useContext, useEffect, useState } from "react";
import React, { useRef } from 'react'
import theme from './theme';
import { FcAssistant, FcDonate, FcInTransit, FcCollaboration, FcFeedback, FcTimeline, FcUpload, FcExternal, FcInvite, FcInspection, FcAbout, FcFolder } from 'react-icons/fc'
import emailjs from '@emailjs/browser'
import { NavLink, useNavigate } from 'react-router-dom'
import { db } from './firebase';
import { collection, getDocs, addDoc, QuerySnapshot, getDoc, doc, updateDoc, arrayUnion, where, query, onSnapshot } from 'firebase/firestore';
import { MdEmail } from 'react-icons/md';
import { EmailIcon } from '@chakra-ui/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import './swiperStyle.css'

const HowItWorksCarousel = () => {
    return (

        <Swiper navigation={true}  modules={[Navigation]} className="mySwiper">

            <SwiperSlide>
                <Box w={'100%'} h={'25vh'} bg={'transparent'}>
                    <Icon as={FcUpload} boxSize={20} mt={'5%'} />
                    <Text fontSize={'lg'} color={'gray.500'} mt={'5%'}>
                        Contractors upload the tender project with a few clicks...
                    </Text>
                </Box>
            </SwiperSlide>
            <SwiperSlide>
                <Box w={'100%'} h={'25vh'} bg={'transparent'} >
                    <Icon as={FcInvite} boxSize={20} mt={'5%'} />
                    <Text fontSize={'lg'} color={'gray.500'} mt={'5%'}>
                        Subcontractors are invited or join the project themselves...
                    </Text>
                </Box>
            </SwiperSlide>
            <SwiperSlide>
                <Box w={'100%'} h={'25vh'} bg={'transparent'} >
                    <Icon as={FcAbout} boxSize={20} mt={'5%'} />
                    <Text fontSize={'lg'} color={'gray.500'} mt={'5%'}>
                        The contractor responds to the request or seeks an answer from clients...
                    </Text>
                </Box>
            </SwiperSlide>
            <SwiperSlide>
                <Box w={'100%'} h={'25vh'} bg={'transparent'}>
                    <Icon as={FcInspection} boxSize={20} mt={'5%'} />
                    <Text fontSize={'lg'} color={'gray.500'} mt={'5%'}>
                        Both sides agree to mark the RFI as resolved...
                    </Text>
                </Box>
            </SwiperSlide>
            <SwiperSlide>
                <Box w={'100%'} h={'25vh'} bg={'transparent'} >
                    <Icon as={FcFolder} boxSize={20} mt={'5%'} />
                    <Text fontSize={'lg'} color={'gray.500'} mt={'5%'}>
                        The RFI is archived, but can still be accessed at any point in the future...
                    </Text>
                </Box>
            </SwiperSlide>
        </Swiper>
    );
};

const Feature = ({ title, text, icon }) => {
    return (
        <Stack alignItems={'center'}>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text color={'gray.600'}>{text}</Text>
        </Stack>
    )
}

function SimpleThreeColumns() {
    return (
        <Box p={4}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={12}>
                <Feature
                    icon={<Icon as={FcCollaboration} w={10} h={10} />}
                    title={'Streamlined RFI Submission'}
                    text={
                        'Submit Requests for Information (RFIs) effortlessly with our intuitive platform, ensuring swift and secure submissions while saving time and minimizing errors.'
                    }
                />
                <Feature
                    icon={<Icon as={FcTimeline} w={10} h={10} />}
                    title={'Transparent RFI Process'}
                    text={
                        'Ensure accountability with transparent workflows and real-time status updates at every step of the RFI process.'
                    }
                />
                <Feature
                    icon={<Icon as={FcFeedback} w={10} h={10} />}
                    title={'Instant RFI Correspondences'}
                    text={
                        'Generate and send comprehensive RFI documents to subcontractors with just one click, eliminating tedious writing tasks.'
                    }
                />
            </SimpleGrid>
        </Box>
    )
}

function CallToAction() {
    return (
        <>
            <Container maxW={'3xl'}>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    alignItems={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 10, md: 28 }}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}
                    >
                        Streamlined Tender <br />
                        <Text as={'span'} color={'teal.400'}>
                            Collaboration
                        </Text>
                    </Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        Good communication benefits everyone in project tenders - work together using TenderPulse to win more tenders.
                    </Text>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        TenderPulse is a place
                    </Text>
                    <Button

                        bg={'teal.400'}
                        w={'20%'}
                        rounded={'full'}
                        px={6}
                        size={'lg'}
                        _hover={{
                            bg: 'teal.500',
                        }}>
                        Get Started
                    </Button>

                </Stack>
            </Container>
        </>
    )
}


const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <Box>
            <Heading size={'2xl'} color={'teal.400'}>{timeLeft.days} : {timeLeft.hours < 10 && '0'}{timeLeft.hours} : {timeLeft.minutes < 10 && '0'}{timeLeft.minutes} : {timeLeft.seconds < 10 && '0'}{timeLeft.seconds} </Heading>
        </Box>
    );
};

export default function LandingPage() {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const targetDate = moment.tz('2024-02-12T09:00:00', 'Australia/Sydney').toDate();


    const updateDiscountClicks = async () => {
        const ref = doc(db, "data", "numbers")
        const data = getDoc(ref)
        const newClicks = (await data).data().discountClicks + 1

        await updateDoc(ref, {
            discountClicks: newClicks
        })
    }

    const updateSignUpClicks = async () => {
        const ref = doc(db, "data", "numbers")
        const data = getDoc(ref)
        const newClicks = (await data).data().signUpClicks + 1

        await updateDoc(ref, {
            signUpClicks: newClicks
        })
    }


    const [isOpen, setIsOpen] = useState(false);
    const initialRef = React.useRef(null)

    const form = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [signupEmail, setSignupEmail] = useState('');
    const [signupName, setSignupName] = useState('');

    const onSignupModalClose = () => {
        setModalOpen()
    }

    const handleButtonClick = () => {
        setModalOpen(true);
        setIsOpen(false);
    };
    const handleSignupSubmit = async () => {
        try {
            const info = {
                name: signupName,
                email: signupEmail
            }
            console.log(info)
            await addDoc(collection(db, 'earlySignups'), {
                info
            })
            toast({
                title: "Success!",
                description: "You have secured your 50% discount, we will reach out shortly to confirm.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setModalOpen(false);
        } catch (error) {
            console.error("Error adding document: ", error);
            toast({
                title: "Error!",
                description: "There was an error submitting your information. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const toast = useToast()

    const showToast = () => {
        toast({
            title: 'Message sent',
            duration: 3000,
            isClosable: true,
            status: 'success',
            position: 'top'
        })
    }


    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current)


        emailjs.sendForm('service_j2ghhem', 'template_8ifhmag', form.current, 'jaWukA3EJN8lNcBj6')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        handleClose();
        showToast();
    };


    const handleSwitchModals = () => {
        setIsOpen(true);
        setAlertIsOpen(false);
    }

    const handleContactClick = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const [alertIsOpen, setAlertIsOpen] = useState(false)

    const handleAlertOpen = () => {
        setAlertIsOpen(true);
        updateSignUpClicks();
    }
    const handleAlertClose = () => {
        setAlertIsOpen(false);
    }

    return (
        <ChakraProvider theme={theme}>
            <Box backgroundColor="#f4f4f4" minHeight="100vh">
                <Flex
                    as="header"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingX={8}
                    paddingY={'1.5%'}
                    backgroundColor="white"
                    boxShadow="md"
                >
                    <Image src="/logo-no-background.svg" alt="Logo" boxSize="15%" />
                    <Flex>
                        <Button variant='link' colorScheme='teal' mr={4} onClick={handleContactClick}>Contact</Button>

                        <Button variant='link' colorScheme='teal' mr={4} onClick={() => { navigate('/Dashboard') }}>Dashboard</Button>

                        <Button variant='link' colorScheme='teal' mr={4} onClick={() => { navigate('/Login') }}>Sign Up</Button>

                    </Flex>
                </Flex>
                <Stack alignItems={'center'}>
                    <Stack
                        as={Box}
                        textAlign={'center'}
                        alignItems={'center'}
                        spacing={{ base: 8, md: 14 }}
                        py={{ base: 10, md: 28 }}>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                            lineHeight={'110%'}
                            color={'gray.700'}
                        >
                            Streamlined Tender <br />
                            <Text as={'span'} color={'teal.400'}>
                                Collaboration
                            </Text>
                        </Heading>
                        <Box w={'65%'}>
                            <Text color={'gray.500'} fontSize={'lg'} mt={'-2%'}>
                                Our intuitive online platform is an all in one solution for both contractors and subcontractors to manage their Requests for Information (RFIs) in one place.
                            </Text>
                        </Box>
                        <Button
                            onClick={() => {navigate('/Login')}}
                            color={'white'}
                            bg={'teal.400'}
                            w={'20%'}
                            rounded={'full'}
                            px={6}
                            size={'lg'}
                            _hover={{
                                bg: 'teal.500',
                            }}>
                            Get Started
                        </Button>
                    </Stack>
                    <Box w={'80%'} mb={'5%'} mt={'1%'}>
                        <HStack>
                            <Box w={'45%'} h={'100%'}>
                                <HowItWorksCarousel />
                            </Box>
                            <Box w={'10%'}></Box>
                            <Box w={'45%'}>
                                <Heading color={'gray.700'}>What is TenderPulse?</Heading>
                                <Text fontSize={'lg'} color={'gray.500'} mt={'2%'}>
                                    TenderPulse allows subcontractors to submit RFIs directly to contractors in real time, without the need of long email chains.
                                    A paper trail is automatically generated at every step of the journey - both sides can see when updates were read, responded to and actioned.
                                </Text>
                            </Box>
                        </HStack>
                    </Box>
                    <Box w={'100%'} bg={'gray.100'} py={'2%'}>
                        <Center>
                            <Box maxW={'70%'}>
                                <SimpleThreeColumns />
                            </Box>
                        </Center>
                    </Box>
                </Stack>
                <Box bg={'white'} h={'5vh'} w={'100%'} borderTopColor={'gray.50'} borderTopWidth={1} color={'gray.700'}>
                    <Stack w={'100%'} align={'center'} mt={'1%'}>
                        <Icon as={MdEmail} />
                        <Link href="mailto:jack@tenderpulse.au">jack@tenderpulse.au</Link>
                        <Link href="mailto:tenderpulsehelp@gmail.com">tenderpulsehelp@gmail.com</Link>
                    </Stack>
                </Box>
            </Box>

            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Contact Us</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form ref={form} onSubmit={sendEmail}>
                            <Input type="text" ref={initialRef} name="user_name" placeholder='Name' />
                            <Input my={'5%'} type="email" name="user_email" placeholder='Email' />
                            <Textarea name="message" placeholder='Your message' />
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={sendEmail}>Send</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={alertIsOpen} size={'lg'} onClose={handleAlertClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack alignItems={'center'}>
                            <Heading color={'gray.700'}>Hold On!</Heading>
                            <Text mb={'5%'} color={'gray.700'} fontSize={'xl'}>We're just putting the finishes on TenderPulse, we'll be ready for launch in: </Text>
                            <CountdownTimer targetDate={targetDate} />
                        </Stack>

                    </ModalBody>

                    <ModalFooter>
                        <Button mx={5} onClick={() => { setAlertIsOpen(false); setModalOpen(true); updateDiscountClicks() }} colorScheme='teal'>Get 50% Off</Button>
                        <Button onClick={handleAlertClose} colorScheme={'teal'}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Heading size="md" color="gray.700">
                            Use code <span style={{ color: 'teal' }}>LAUNCH50</span> at checkout for 50% off your first 3 months with TenderPulse!
                        </Heading>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>


        </ChakraProvider>
    );
}