import React from 'react';
import './App.css';
import Navbar from './navbar.jsx';
import Progress_bar from './progress';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import { UserProvider } from "./contexts/user.context";
import Login from "./Login.js";
import Signup from "./signup.js";
import SettingsPage from './settingsPage';
import LandingPage from './LandingPage.jsx';
import ConfirmationPage from './ConfirmationPage.jsx';
import {Analytics} from '@vercel/analytics/react'

const App = () => {
  return (
    <Router>
      <UserProvider>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<LandingPage />} />

            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/settings" element={<SettingsPage />} />
            <Route exact path="/Dashboard" element={<HomePage />} />
            <Route exact path="/Confirmation" element={<ConfirmationPage />} />
            <Route exact path="/Landing" element={<LandingPage />} />
  
          </Routes>
          <Analytics />
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
